import React from "react"
import NewsSideLinks from "../components/NewsSideLinks"
import SocialMedia from "../components/SocialMedia"
import image from "./bg-breadcrumb.webp"
import { Link } from "react-router-dom"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const ShanghaiNews = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Breadcrumb Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb.webp"
        data-white-overlay="4"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>Shanghai Automotive Testing and Quality Control Expo</h2>
            <ul>
              <li>
                <Link to="/News">
                  News
                  {/* <a href="news-refrigeration.html">News</a> */}
                </Link>
              </li>
              <li>
                <Link to="/News-Shanghai">
                  {/* <a href="shanghai_automotive.html"> */}
                  Shanghai Automotive Testing and Quality Control Expo
                  {/* </a> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Blogs Area --> */}
        <div className="tm-blogs-area tm-padding-section bg-white">
          <div className="container">
            <div className="row no-gutters">
              {/* <!-- Blogs --> */}
              <div className="col-lg-8 col-12">
                <div className="tm-blog-container">
                  <div className="tm-blog blogitem">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\automotive\auto-motive2.webp"
                        alt="blog img"
                      />
                    </div>
                    <div className="tm-blog-bottomside">
                      <div className="tm-blog-tags">
                        <span>
                          <Link to="/News">
                            By: Admin
                            {/* <a href="news-refrigeration.html">Admin</a> */}
                          </Link>
                        </span>
                        <span>Oct 19, 2016</span>
                      </div>
                      <h6>
                        Shanghai Automotive Testing and Quality Control Expo
                      </h6>
                      <p>
                        Leading the world-related industries, "the sixth car
                        test and quality control Expo" in September 14, 2011 in
                        Shanghai Everbright Convention and Exhibition Center was
                        held. The exhibition was hosted by British Professional
                        Exhibition Company UKIP and hosted by Beijing Haifu
                        Exhibition Service Co., Ltd. The main contractors since
                        the exhibition in 2006 from Germany since the
                        introduction of continuous success in Shanghai and
                        Beijing held five similar exhibitions. The exhibition
                        has not only accumulated a wealth of customer resources,
                        but also for the show has won increasing popularity and
                        influence; with the development of China's exhibition
                        economy, "Automotive Testing and Quality Control Expo"
                        with its highly specialized and unique, Has grown to
                        become the global automotive industry generally
                        recognized and actively participate in the annual
                        meeting. The fourth time this year, the company
                        participated in the exhibition, it is self-evident, as
                        China's only for automotive testing and quality
                        engineering technology and services in the field of
                        professional exhibition, its size, effect, influence to
                        the participants left deep impression.
                      </p>
                      <blockquote>
                        <p>
                          Leading the world-related industries, "the sixth car
                          test and quality control Expo" in September 14, 2011.
                        </p>
                      </blockquote>
                    </div>

                    <SocialMedia />

                    {/* <!-- Blogitem Comments --> */}

                    {/* <!--// Blogitem Comments --> */}

                    {/* <!-- Blogitem Commentbox --> */}

                    {/* <!--// Blogitem Commentbox --> */}
                  </div>
                </div>
              </div>
              {/* <!--// Blogs --> */}

              {/* <!-- Sidebar Widgets --> */}
              <div className="col-lg-4 col-12">
                <NewsSideLinks />
              </div>
              {/* <!--// Sidebar Widgets --> */}
            </div>
          </div>
        </div>
        {/* <!--// Blogs Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default ShanghaiNews
