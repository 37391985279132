import React from "react"

const ContactNums = () => {
  return (
    <div>
      <div
        id="tm-contact-area"
        className="tm-contact-area tm-section tm-padding-section bg-white"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="tm-sectiontitle text-center">
                <h2>Get in touch</h2>
                <span className="tm-sectiontitle-divider">
                  <img
                    src="assets/images/icons/icon-section-title-divider.webp"
                    alt="divider icon"
                  />
                </span>
                <p>
                  Feel free to contact us,we are here to at your service by
                  24/7.
                </p>
                <p>Your Passion is our satisfaction</p>
              </div>
            </div>
          </div>
        </div>

        <div className="tm-contact-top">
          <div className="container">
            <div className="row justify-content-center">
              {/* <!-- Single Block --> */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                <div className="tm-contact-block text-center tm-revealblock">
                  <span className="tm-contact-block-icon">
                    <img
                      src="assets/images/icons/icon-contact-address-13.webp"
                      alt="icon"
                    />
                  </span>
                  <div className="tm-contact-block-content">
                    <h5>Pakistan Address</h5>
                    <p>107 Sughra Tower, F-11 Markaz Islamabad | Pakistan</p>
                  </div>
                </div>
              </div>
              {/* <!--// Single Block --> */}

              {/* <!-- Single Block --> */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                <div className="tm-contact-block text-center tm-revealblock">
                  <span className="tm-contact-block-icon">
                    <img
                      src="assets/images/icons/icon-contact-call-14.webp"
                      alt="icon"
                    />
                  </span>
                  <div className="tm-contact-block-content">
                    <h5>Phone</h5>

                    <p>
                      <a href="tel:+92-51-2224822">+92-51-2224822 </a>
                    </p>
                    <p>
                      {" "}
                      <a href="tel:+92-300-5979100">+92-300-5979100</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--// Single Block --> */}

              {/* <!-- Single Block --> */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                <div className="tm-contact-block text-center tm-revealblock">
                  <span className="tm-contact-block-icon">
                    <img
                      src="assets/images/icons/icon-contact-email-15.webp"
                      alt="icon"
                    />
                  </span>
                  <div className="tm-contact-block-content">
                    <h5>Email</h5>
                    <p>
                      {" "}
                      <a href="Email">info@jiudingref.com.pk</a>
                    </p>
                    <p>
                      {" "}
                      <a href="Personal-Email">afridi@Jiudingref.com.pk</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--// Single Block --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactNums
