const Video = () => {
  return (
    // <div className="tm-heroslider">
    <video
      autoPlay
      loop
      controls
      muted
      width="100%"
      type="video/mp4"
      className="e_video e_video-000 p_video"
      webkit-playsnline="true"
      x-webkit-airplay="true"
      playsInline
      x5-video-player-type="h5"
      x5-video-orientation="h5"
      x5-video-player-fullscreen="true"
      id="/"
    >
      <source src="https://v4.cecdn.yun300.cn/100001_2104065041/10.27%E4%B9%85%E9%BC%8E%E5%88%B6%E5%86%B7%E8%8A%B1%E7%B5%AEcodec.mp4" />
      <source src="https://v4.cecdn.yun300.cn/100001_2104065041/10.27%E4%B9%85%E9%BC%8E%E5%88%B6%E5%86%B7%E8%8A%B1%E7%B5%AEcodec.mp4" />
      Sorry, your browser doesn't support embedded video.
    </video>
    // </div>
  );
};

export default Video;
