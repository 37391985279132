import React from "react";
// import { AnimatePresence } from "framer-motion"
import { Routes, Route } from "react-router-dom";

import Home from "./Home";

import AboutDetail2 from "../Pages/About/Pages/AboutDetail2";

import OverView from "../Pages/Products/pages/ProductsOverView";

import IceSkatingRink from "../Pages/Products/pages/IceSlatingPages/IceSkatingRink";
import DetailIceRink from "../Pages/Products/pages/IceSlatingPages/DetailIceRink";
import AirTreatmentProducts from "../Pages/Products/pages/AirTreatmentSeries/AirTreatmentProducts";
import RefrigeratingOverview from "../Pages/Products/pages/RefrigeratingUnits/RefrigeratingOverview";
import FoodRefrigerationOverView from "../Pages/Products/pages/FoodRefrigeration/FoodRefrigerationOverView";
import EnvironmentalSystemsOverView from "../Pages/Products/pages/EnvironmentalSystems/EnvironmentalSystemsOverView";

import ServicesDetail from "../Pages/ServicesDetails/Pages/ServicesMainPage/ServicesDetail";

import OurRewars from "../Pages/TestimonialArea/pages/Honor/OurRewars";
import Qualifications from "../Pages/TestimonialArea/pages/Qualification/Qualifications";
import Patents from "../Pages/TestimonialArea/pages/Patents/Patents";

import News from "../Pages/NewsArea/Pages/News/NewsOverView";
import PakistanProject from "../Pages/BrandLogos/pages/OurProjects/PakisatanProjects";
import AutoMobile from "../Pages/BrandLogos/pages/OurProjects/AutoMobile";
import IceRinkProject from "../Pages/BrandLogos/pages/OurProjects/IceRinkProject";
import MetallurgyProject from "../Pages/BrandLogos/pages/OurProjects/MetallurgyProject";
import ElectronicsProject from "../Pages/BrandLogos/pages/OurProjects/ElectronicsProject";
import FoodBeveragesProject from "../Pages/BrandLogos/pages/OurProjects/FoodBeveragesProject";

import ContactDetails from "../Pages/ContactArea/DetailPage/ContactDetails";
import MedicineMedical from "../Pages/BrandLogos/pages/OurProjects/Medicine&Medical";
import HuangshanNews from "../Pages/NewsArea/Pages/News/HuangshanNews";
import ShanghaiNews from "../Pages/NewsArea/Pages/News/ShanghaiNews";
import MidAutumNews from "../Pages/NewsArea/Pages/News/MidAutumNews";
import TeaPartyNews from "../Pages/NewsArea/Pages/News/TeaPartyNews";
import QingdaoNews from "../Pages/NewsArea/Pages/News/QingdaoNews";
import PharmaceuticalNews from "../Pages/NewsArea/Pages/News/PharmaceuticalNews";
import NotFound from "../Pages/PageNotFound/PageNotFound";

const Routing = () => {
  // const location = useLocation();
  return (
    <>
      {/* <AnimatePresence  initial={false}> */}
      <Routes>
        {/* // location={location} key={location.pathname} */}
        {/* > */}
        <Route exact path="/" element={<Home />} />
        {/* about area */}
        <Route path="About" element={<AboutDetail2 />} />
        {/* about area */}
        {/* Products */}
        <Route exact path="Products" element={<OverView />} />
        <Route path="product-ice-rink" element={<IceSkatingRink />} />
        <Route path="detail-ice-rink" element={<DetailIceRink />} />
        <Route
          path="product-air-treatment"
          element={<AirTreatmentProducts />}
        />
        <Route
          path="product-refrigerating-unit"
          element={<RefrigeratingOverview />}
        />
        <Route
          path="product-food-refrigeration"
          element={<FoodRefrigerationOverView />}
        />
        <Route
          path="product-enviromental-systems"
          element={<EnvironmentalSystemsOverView />}
        />
        {/* Products */}
        {/* Services */}
        <Route exact path="Services" element={<ServicesDetail />} />
        {/* Services */}
        {/* Honor */}
        <Route exact path="Honor" element={<OurRewars />} />
        <Route path="Qualifications" element={<Qualifications />} />
        <Route path="Patents" element={<Patents />} />
        {/* Honor */}
        {/* news/Blog */}
        <Route exact path="News" element={<News />} />
        <Route path="News-Huangshan" element={<HuangshanNews />} />
        <Route path="News-Shanghai" element={<ShanghaiNews />} />
        <Route path="News-Mid-Autumn" element={<MidAutumNews />} />
        <Route path="News-Tea-Party" element={<TeaPartyNews />} />
        <Route path="News-Qingdao" element={<QingdaoNews />} />
        <Route path="News-Pharmaceutical" element={<PharmaceuticalNews />} />
        {/* news/Blog */}
        {/* Brand logo/Projects */}
        <Route exact path="Projects" element={<PakistanProject />}></Route>
        <Route exact path="Pakistan" element={<PakistanProject />} />
        <Route path="AutoMobile" element={<AutoMobile />} />
        <Route path="IceRink" element={<IceRinkProject />} />
        <Route path="Metallurgy" element={<MetallurgyProject />} />
        <Route path="Electronics" element={<ElectronicsProject />} />
        <Route path="Food&Beverages" element={<FoodBeveragesProject />} />
        <Route path="Medicine&Medical" element={<MedicineMedical />} />
        {/* Brand logo/Projects */}
        {/* Contacts inner page */}
        <Route exact path="Contact" element={<ContactDetails />} />
        {/* Contacts inner page */}
        <Route exact path="*" element={<NotFound />} />
      </Routes>
      {/* </AnimatePresence> */}
    </>
  );
};

export default Routing;
