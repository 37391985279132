import React from "react"
import Contacts2Num from "../../components/ContactTwoNums"
import SaleOffers from "../../components/SaleOffers"
import SideLinkProjects from "../../components/SideLinkProjects"
import ProjectHeroslider from "../../components/ProjectHeroslider"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const IceRinkProject = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Heroslider Area --> */}
      <ProjectHeroslider />
      {/* <!--// Heeroslider Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- products --> */}
        <div
          id="tm-products-area"
          className="tm-products-area tm-section tm-padding-section bg-white"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tm-sectiontitle text-center">
                  <h2>Our Projects</h2>
                  <span className="tm-sectiontitle-divider">
                    <img
                      src="assets/images/icons/icon-section-title-divider.webp"
                      alt="divider icon"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row tm-products-slider tm-slider-arrows">
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-ice-rink\icerink-bergstrom.webp"
                        data-caption="icerink-Bergstrom"
                      >
                        <img
                          src="\assets\images\projects\pro-ice-rink\icerink-bergstrom.webp"
                          alt="Product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-ice-rink\icerink-polarbear.webp"
                        data-caption="icerink-polarbear"
                      >
                        <img
                          src="\assets\images\projects\pro-ice-rink\icerink-polarbear.webp"
                          alt="Product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-ice-rink\icerink-sino.webp"
                        data-caption="icerink-sino"
                      >
                        <img
                          src="\assets\images\projects\pro-ice-rink\icerink-sino.webp"
                          alt="Product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-ice-rink\icerink-taijink.webp"
                        data-caption="Icerink-Taijink"
                      >
                        <img
                          src="\assets\images\projects\pro-ice-rink\icerink-taijink.webp"
                          alt="Product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-ice-rink\icerink-xnchai.webp"
                        data-caption="Icerink Xnchai"
                      >
                        <img
                          src="\assets\images\projects\pro-ice-rink\icerink-xnchai.webp"
                          alt="Product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tm-section products-page-content tm-padding-section bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-12 order-1 order-lg-2">
                    <div className="tm-shop-products">
                      <div className="row mt-30-reverse">
                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-ice-rink\icerink-bergstrom.webp"
                              alt="Bergstrom"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Begstrom</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-ice-rink\icerink-polarbear.webp"
                              alt="Polar Bear"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Polar Bear</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-ice-rink\icerink-sino.webp"
                              alt="Sino"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Sino</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-ice-rink\icerink-taijink.webp"
                              alt="Tai Jink"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Tai Jink</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-ice-rink\icerink-xnchai.webp"
                              alt="Xinchai"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Xinchai</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-12 order-2 order-lg-1">
                    <div className="widgets widgets-sidebar-shop">
                      <SideLinkProjects />
                      {/* <!--// Single Widget -->

                                        <!-- Single Widget -->

                                        <!--// Single Widget --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Call To Action --> */}
        <div
          id="tm-calltoaction-area-2"
          className="tm-cta-2-area tm-section tm-padding-section bg-theme"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8">
                <SaleOffers />
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Call To Action --> */}

        {/* <!-- Contact Area --> */}
        <Contacts2Num />
        {/* <!--// Contact Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default IceRinkProject
