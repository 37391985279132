import React from "react"
import ContactNums from "../../components/ContactTwoNums"
import SaleOffers from "../../components/SaleOffers"
import SideLinkProjects from "../../components/SideLinkProjects"
import ProjectHeroslider from "../../components/ProjectHeroslider"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const ElectronicsProject = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Heroslider Area --> */}
      <ProjectHeroslider />
      {/* <!--// Heeroslider Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- products --> */}
        <div
          id="tm-products-area"
          className="tm-products-area tm-section tm-padding-section bg-white"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tm-sectiontitle text-center">
                  <h2>Our Projects</h2>
                  <span className="tm-sectiontitle-divider">
                    <img
                      src="assets/images/icons/icon-section-title-divider.webp"
                      alt="divider icon"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row tm-honor-slider tm-slider-arrows">
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-electronics\Chinese_Academy_of_Metrology.webp"
                        data-caption="Chinese_Academy_of_Metrology.webp"
                      >
                        <img
                          src="\assets\images\projects\pro-electronics\Chinese_Academy_of_Metrology.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-electronics\Nanjing_Wu_Institute_of_Technology.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-electronics\Nanjing_Wu_Institute_of_Technology.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-electronics\Samsung_Semiconductor.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-electronics\Samsung_Semiconductor.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Single Product --> */}

              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-electronics\Shanghai_Jiujing_Electronic_Material.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-electronics\Shanghai_Jiujing_Electronic_Material.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-electronics\Shenyang_Aircraft_Industry_Group_Ltd.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-electronics\Shenyang_Aircraft_Industry_Group_Ltd.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tm-section products-page-content tm-padding-section bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-12 order-1 order-lg-2">
                    <div className="tm-shop-products">
                      <div className="row mt-30-reverse">
                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-electronics\Chinese_Academy_of_Metrology.webp"
                              alt="Chinese_Academy_of_Metrology"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Chinese Academy <br />
                                of Metrology
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-electronics\Nanjing_Wu_Institute_of_Technology.webp"
                              alt="engropolymer"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Nanjing wu <br />
                                Institute of Techonology
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-electronics\Samsung_Semiconductor.webp"
                              alt="engropolymer"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Samsung</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-electronics\Shanghai_Jiujing_Electronic_Material.webp"
                              alt="engropolymer"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Shanghai Jiujing <br />
                                Electronic
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-electronics\Shenyang_Aircraft_Industry_Group_Ltd.webp"
                              alt="engropolymer"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Shenyang Aircraft <br />
                                Industry
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-electronics\Siemens.webp"
                              alt="engropolymer"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Siemens</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-12 order-2 order-lg-1">
                    <div className="widgets widgets-sidebar-shop">
                      <SideLinkProjects />
                      {/* <!--// Single Widget --> */}

                      {/* <!-- Single Widget --> */}

                      {/* <!--// Single Widget --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Call To Action --> */}
        <div
          id="tm-calltoaction-area-2"
          className="tm-cta-2-area tm-section tm-padding-section bg-theme"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <SaleOffers />
            </div>
          </div>
        </div>
        {/* <!--// Call To Action --> */}

        {/* <!-- Brand Logos --> */}

        {/* <!--// Brand Logos --> */}

        {/* <!-- Contact Area --> */}
        <ContactNums />
        {/* <!--// Contact Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default ElectronicsProject
