import React from "react"
import Slider from "react-slick"
import { Link } from "react-router-dom"
const Carousel = () => {
  const sliderSettings = {
    className: "center",
    // centerPadding: "3px",
    // centerMode: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 2500,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 991,
        settings: {
        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToShow: 3,
        slidesToScroll: 1

        }
      },
      {
        breakpoint: 767,
        settings: {
        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToShow: 1,
        slidesToScroll: 1

        }
      },

      

    ]

  }
  return (
    <div className="center">
      {/* <Link to="/Pakistan" className="tm-brandlogo"> */}
      <Slider {...sliderSettings}>
        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-1.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-2.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-3.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-4.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-5.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-6.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-7.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-8.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-9.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-10.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-11.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-12.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-13.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-14.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-15.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-16.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-17.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-18.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-19.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-20.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-21.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-22.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-23.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-24.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-25.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-26.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-27.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-79.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-81.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-82.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-83.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-84.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-85.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-86.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-87.webp"
              alt="brand-logo"
            />
          </Link>
        </div>

        <div className="tm-brandlogo">
          <Link to="/Pakistan">
            <img
              src="assets/images/brandlogo/brandlogo-88.webp"
              alt="brand-logo"
            />
          </Link>
        </div>
      </Slider>
      {/* </Link> */}
    </div>
  )
}

export default Carousel
