import React from "react"
import Slider from "react-slick"
import { Link } from "react-router-dom"

// classname is purposely written wrong to avoid the untracked behaviour of div's while applying slickslider.
const SlickS = () => {
  const settings = {
    centerPadding: "5px",
    infinite: true,
    autoplay: true,
    dots: true,
    arrow: true,
    slidesToShow: 3,
    slideNum: 2,
    slidesToScroll: 2,
    autoplaySpeed: 3000,
    // speed:1000,
    cssEase: "linear",

    // responsive: [
    //   {
    //     breakpoint: 768,
    //     settings: {
    //     slidesToShow: 1,
    //     centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
    //     slidesToScroll: 1
    //     }
    //   }
    //  ]
     responsive: [
      {
        breakpoint: 991,
        settings: {
        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToShow: 2,
        slidesToScroll: 1

        }
      },
      {
        breakpoint: 767,
        settings: {
        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToShow: 1,
        slidesToScroll: 1

        }
      }
    ]
  }

  return (
    <div className="center">
      <Slider {...settings}>
        <div>
          <Link to="/News-Huangshan">
            {/* <a href="news-huangshan.html"> */}
            <img
              src="assets/images/news/huangshan/huangshan_trip.webp"
              alt="blog logo"
            />
            {/* </a> */}
          </Link>
          <div className="tm-blog-tags">
            <span>
              <Link to="/News">
                {/* <a href="news-refrigeration.html"> */}
                By: Admin
                {/* </a> */}
              </Link>
            </span>
            <span>Oct 19, 2016</span>
          </div>
          <Link to="/News-Huangshan">
            {/* <a href="news-huangshan.html"> */}
            <h6>Autumn in October Huangshan trip</h6>
            <p style={{ color: "#000000" }}>
              In order to thank employees for the company's development process
              to make unremitting efforts and selfless dedication; also to
              promote staff exchanges, strengthen the team between the seamless
              docking, enhance friendship and enhance cohesion;
            </p>
            {/* </a> */}
          </Link>
          <Link to="/News-Huangshan" className="tm-readmore">
            {/* <a href="news-huangshan.html" className="tm-readmore" > */}
            Read more <i className="ion-arrow-right-c"></i>
            {/* </a> */}
          </Link>
        </div>

        <div>
          <Link to="/News-Shanghai">
            {/* <a href="news-shanghai_automotive.html"> */}
            <img
              src="assets/images/news/automotive/auto-motive2.webp"
              alt="blog logo"
            />
            {/* </a> */}
          </Link>
          <div className="tm-blog-tags">
            <span>
              <Link to="/News">
                {/* <a href="news-refrigeration.html"> */}
                By: Admin
                {/* </a> */}
              </Link>
            </span>
            <span>Sep 16, 2011</span>
          </div>
          <Link to="/News-Shanghai">
            {/* <a href="news-shanghai_automotive.html"> */}
            <h6>Shanghai Automotive Testing and Quality Control Expo</h6>
            <p style={{ color: "#000000" }}>
              Leading the world-related industries, "the sixth car test and
              quality control Expo" in September 14, 2011 in Shanghai Everbright
              Convention and Exhibition Center was held.
            </p>
            {/* </a> */}
          </Link>
          <Link to="/News-Shanghai" className="tm-readmore">
            {/* <a href="news-shanghai_automotive.html" className="tm-readmore"> */}
            Read more <i className="ion-arrow-right-c"></i>
            {/* </a> */}
          </Link>
        </div>

        <div>
          <Link to="/News-Mid-Autumn">
            {/* <a href="news-mid-autumn.html"> */}
            <img
              src="assets/images/news/autumnfestival/autumn-festival.webp"
              alt="blog logo"
            />
            {/* </a> */}
          </Link>
          <div className="tm-blog-tags">
            <span>
              <Link to="/News">
                {/* <a href="news-refrigeration.html"> */}
                By: Admin
                {/* </a> */}
              </Link>
            </span>

            <span>Sep 08, 2011</span>
          </div>
          <Link to="/News-Mid-Autumn">
            {/* <a href="news-mid-autumn.html"> */}
            <h6>Mid-Autumn Festival, National Day holiday notice</h6>

            <p style={{ color: "#000000" }}>
              According to the "General Office of the State Council announced in
              2011 part of the holiday arrangements notice", combined with the
              company's actual situation,is the 2011 Mid-Autumn Festival,
              National Day holiday schedule is as follows:
            </p>
            {/* </a> */}
          </Link>
          <Link to="/News-Mid-Autumn" className="tm-readmore">
            {/* <a href="news-mid-autumn.html" className="tm-readmore"> */}
            Read more <i className="ion-arrow-right-c"></i>
            {/* </a> */}
          </Link>
        </div>

        <div>
          <Link to="/News-Tea-Party">
            {/* <a href="news-jiuding-tea-party.html"> */}
            <img
              src="assets/images/news/tea-party/teaparty1.webp"
              alt="blog logo"
            />
            {/* </a> */}
          </Link>
          <div className="tm-blog-tags">
            <span>
              <Link to="/News">
                {/* <a href="news-refrigeration.html"> */}
                By: Admin
                {/* </a> */}
              </Link>
            </span>

            <span>Oct 19, 2016</span>
          </div>
          <Link to="/News-Tea-Party">
            {/* <a href="news-jiuding-tea-party.html"> */}
            <h6>Jiuding New Year tea party</h6>
            <p style={{ color: "#000000" }}>
              Yu rabbit old age, Jinlong welcome the new spring. January 15,
              Jiuding refrigeration in the Luhe East Hall held a grand welcome
              spring tea party and annual recognition of the General Assembly.
            </p>
            {/* </a> */}
          </Link>
          <Link to="/News-Tea-Party" className="tm-readmore">
            {/* <a href="news-jiuding-tea-party.html" className="tm-readmore"> */}
            Read more <i className="ion-arrow-right-c"></i>
            {/* </a> */}
          </Link>
        </div>

        <div>
          <Link to="/News-Qingdao">
            {/* <a href="news-qingdao.html"> */}
            <img src="assets/images/news/qindgo/qindigo.webp" alt="blog logo" />
            {/* </a> */}
          </Link>
          <div className="tm-blog-tags">
            <span>
              <Link to="/News">
                {/* <a href="news-refrigeration.html"> */}
                By: Admin
                {/* </a> */}
              </Link>
            </span>

            <span>July 25, 2011</span>
          </div>
          <Link to="/News-Qingdao">
            {/* <a href="news-qingdao.html"> */}
            <h6>
              3rd China (Qingdao) International API and Intermediates Exhibition
            </h6>
            <p style={{ color: "#000000" }}>
              (Qingdao) International Pharmaceutical Machinery and Packaging
              Equipment Exhibition (PI & PM), the third China (Qingdao)
              International Drugstore .
            </p>
            {/* </a> */}
          </Link>
          <Link to="/News-Qingdao" className="tm-readmore">
            {/* <a href="news-qingdao.html" className="tm-readmore"> */}
            Read more <i className="ion-arrow-right-c"></i>
            {/* </a> */}
          </Link>
        </div>

        <div>
          <Link to="/News-Pharmaceutical">
            {/* <a href="news-pharmaceutical.html"> */}
            <img
              src="assets/images/news/n41china/n41china-first-image.webp"
              alt="blog logo"
            />
            {/* </a> */}
          </Link>
          <div className="tm-blog-tags">
            <span>
              <Link to="/News">
                {/* <a href="news-refrigeration.html"> */}
                By: Admin
                {/* </a> */}
              </Link>
            </span>

            <span>Jun 03, 2011</span>
          </div>
          <Link to="/News-Pharmaceutical">
            {/* <a href="news-pharmaceutical.html"> */}
            <h6>The 41st China International Pharmaceutical Machinery Fair</h6>
            <p style={{ color: "#000000" }}>
              Organized by the China Pharmaceutical Equipment Industry
              Association of China International Pharmaceutical Machinery Expo
              since 1991 has been successfully..
            </p>
            {/* </a> */}
          </Link>
          <Link to="/News-Pharmaceutical" className="tm-readmore">
            {/* <a href="news-pharmaceutical.html" className="tm-readmore"> */}
            Read more <i className="ion-arrow-right-c"></i>
            {/* </a> */}
          </Link>
        </div>
      </Slider>
    </div>
  )
}

export default SlickS
