import React from "react"
// import { Routes, Route } from "react-router-dom"
import { NavLink } from "react-router-dom"

const HonorSideDash = () => {
  return (
    <div>
      <div className="single-widget widget-categories">
        <h6 className="widget-title">CERTIFICATES</h6>
        <ul>
          <li>
            <NavLink
              to="/Honor"
              style={({ isActive }) =>
                isActive ? { color: "#e2000b" } : { color: "" }
              }
            >
              {/* <a href="honors-refrigeration.html"> */}
              Honors
              {/* </a> */}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Qualifications"
              style={({ isActive }) =>
                isActive ? { color: "#e2000b" } : { color: "" }
              }
            >
              {/* <a href="qualification-refrigeration.html"> */}
              Qualifications
              {/* </a> */}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Patents"
              style={({ isActive }) =>
                isActive ? { color: "#e2000b" } : { color: "" }
              }
            >
              {/* <a href="patent-refrigeration.html"> */}
              Patents
              {/* </a> */}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HonorSideDash
