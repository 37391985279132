import React from "react"
import "./App.css"
import Footer from "./Pages/FooterArea/Footer";
import ScrollButton from "./components/ButtonRedToTop/ScrollButton";
import Routing from "./Home/Routing"
// import Animation1 from "./components/LocationProvider/RoutesWithAnimation";



const App = () => {
  return (
    <div className="wrapper">

      {/* <main className="page-content"> */}
      <Routing />
      {/* </main> */}
      <footer className="tm-footer">
        <Footer />
      </footer>
      <ScrollButton />
    </div>
  )
}
export default App
