import React from 'react'

const SaleOffers = () => {
  return (
    <div>
      <div className="tm-cta tm-cta-2 text-center">
        <h2> Sales offices and Services Offices </h2>
        <p>
          {" "}
          Beijing, Nanjing, Jinan, Shijiazhuang, Shenyang, Wuhan, Chongqing,
          Hefei, Zhengzhou, Nanchang, Shanghai, Guangzhou, Taiyuan, Tianjin,
          Xiamen, Changchun, Harbin, Chengdu, Guiyang, Xi’an, Hangzhou,
          Changsha, Nanning, Dalian, Qingdao, Urumchi, Tongliao and other large
          and medium-sized cities
        </p>
        <span className="mail-btn">
          <a href="mailto:contact@jiuding.com">contact@jiuding.com</a>
        </span>
      </div>
    </div>
  )
}

export default SaleOffers