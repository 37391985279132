import React from "react";
import { Link } from "react-router-dom";

const About1 = () => {
  return (
    <>
      <div id="about" className="about tm-padding-section-index bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="tm-videobox">
                <img
                  src="assets/images/heroslider/videobox-image-3.webp"
                  alt="videobox logo"
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "60%",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tm-about-content">
                <h2>About our company you can trust</h2>

                <p style={{ textAlign: "justify" }}>
                  Nanjing Jiuding Refrigeration & Air Conditioning Equipment
                  Co., Ltd., formerly known as Nanjing Jianmao Refrigeration &
                  Air Conditioning Equipment Co., Ltd., was founded in 2003 and
                  our company a professional Chinese manufacturer that engages
                  in refrigeration and air treatment equipment and a high-tech
                  enterprise that integrates science, industry and trade. We
                  take creating pleasant, natural room of life and meticulous,
                  exquisite production environment as our bounden duty and
                  commit ourselves to the development and production of
                  refrigeration and air treatment facilities in such realms as
                  industry, business and military filed. We also have two
                  subsidiaries, that is
                </p>
                <ul className="stylish-list-color">
                  <li>
                    <i className="ion-android-checkmark-circle"></i> Nanjing
                    Jiuding Refrigeration & Air Conditioning Equipment Co., Ltd.
                  </li>
                  <li>
                    <i className="ion-android-checkmark-circle"></i> Nanjing
                    Jiuding Refrigeration & Heating Equipment Engineering Co.,
                    Ltd.
                  </li>
                </ul>
                <div className="tm-about-buttons tm-buttongroup">
                  <Link to="/About" className="tm-button">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About1;
