import React from "react"
import image from "./bg-breadcrumb-projects.webp"

const ProjectHeroslider = () => {
  return (
    <div>
      {" "}
      {/* <!-- Heroslider Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}
        // data-bgimage="assets/images/bg/bg-breadcrumb-projects.webp"
      ></div>
      {/* <!--// Heeroslider Area --> */}
    </div>
  )
}

export default ProjectHeroslider
