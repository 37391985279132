import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";

const FunFact = () => {
  const [odometerValue1, setOdometerValue1] = useState(0);
  const [odometerValue2, setOdometerValue2] = useState(0);
  const [odometerValue3, setOdometerValue3] = useState(0);
  const [odometerValue4, setOdometerValue4] = useState(0);
  const [animationTriggered, setAnimationTriggered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!animationTriggered && window.scrollY > 950) {
        setOdometerValue1(180); // Set your desired final value
        setOdometerValue2(300); // Set your desired final value
        setOdometerValue3(100); // Set your desired final value
        setOdometerValue4(20); // Set your desired final value
        setAnimationTriggered(true); // Mark animation as triggered
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      // console.log("remove");
    };
  }, [animationTriggered]);

  return (
    <div id="funfact" className=" tm-section tm-padding-section-index bg-grey">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-12">
            <h2 className="tm-funfact-heading">
              <small>More than</small>
              <span className="tm-funfact-number tm-funfact-content">
                <Odometer value={odometerValue4} format="d" />
                {/* <span class="odometer" data-count-to="20"></span> */}
              </span>
              <span> years</span> of experience
            </h2>

            {/* <h2 className="tm-funfact-heading">
                <small>More than</small>
                <span> 
                <Odometer value={odometerValue4} format="d"  />

                  <span>years</span>
                  
                  </span> 
                  of experience
              </h2> */}
          </div>
          <div className="col-lg-7 col-12">
            <div className="row mt-30-reverse justify-content-center">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-30">
                <div className="tm-funfact">
                  <div className="tm-funfact-icon">
                    <img
                      src="assets/images/icons/icon-funfact-4.webp"
                      alt="funfact icon"
                    />
                  </div>

                  <div className="counter tm-funfact-content">
                    <span
                      className="number odometer tm-funfact-number"
                      // id="datasources"
                    >
                      <Odometer
                        value={odometerValue1}
                        format="d"
                        className="counter tm-funfact-content"
                      />
                    </span>
                    <h6 className="label">Projects Completed</h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-30">
                <div className="tm-funfact">
                  <div className="tm-funfact-icon">
                    <img
                      src="assets/images/icons/icon-funfact-5.webp"
                      alt="funfact icon"
                    />
                  </div>

                  <div className="counter tm-funfact-content">
                    <span className="number odometer tm-funfact-number">
                      <Odometer
                        value={odometerValue2}
                        format="d"
                        className="counter tm-funfact-content"
                      />
                    </span>
                    <h6 className="label">Expert Workers</h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-30">
                <div className="tm-funfact">
                  <div className="tm-funfact-icon">
                    <img
                      src="assets/images/icons/icon-service-warranty.webp"
                      alt="funfact icon"
                    />
                  </div>

                  <div className="counter tm-funfact-content">
                    <span className="number odometer tm-funfact-number">
                      <Odometer
                        value={odometerValue3}
                        format="d"
                        className="counter tm-funfact-content"
                      />
                      %
                    </span>
                    <h6 className="label">Service Warranty</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunFact;
