import React from "react"
import SlickS from "./Pages/components/BlogSlider"

const Blog = () => {
  return (
    <>
      <div
        id="news"
        className=" tm-section tm-padding-section-index bg-grey"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="tm-sectiontitle text-center">
                <h2>Latest News</h2>

                <span className="tm-sectiontitle-divider">
                  <img
                    src="assets/images/icons/icon-section-title-divider.webp"
                    alt="divider icon"
                  />
                </span>
              </div>
            </div>
          </div>

          <SlickS />
        </div>
      </div>
    </>
  )
}

export default Blog
