import styled from "styled-components";

export const Button = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  height: 45px;
  width: 45px;
  line-height: 43px;
  text-align: center;
  font-size: 45px;
  border-radius: 100%;
  z-index: 1;
  cursor: pointer;
  color: #b92b27;
`;
