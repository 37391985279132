import React from "react"
import NewsSideLinks from "../components/NewsSideLinks"
import image from "./bg-breadcrumb.webp"
import { Link } from "react-router-dom"
import SocialMedia from "../components/SocialMedia"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"
const HuangshanNews = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Breadcrumb Area --> */}

      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb.webp"
        data-white-overlay="4"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>Autumn in October Huangshan trip</h2>
            <ul>
              <li>
                <Link to="/News">News</Link>
                {/* <a href="news-refrigeration.html">News</a> */}
              </li>
              <li>
                <Link to="/News-Huangshan">
                  {/* <a href="news-huangshan.html"> */}
                  Autumn in October Huangshan trip
                  {/* </a> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Blogs Area --> */}
        <div className="tm-blogs-area tm-padding-section bg-white">
          <div className="container">
            <div className="row no-gutters">
              {/* <!-- Blogs --> */}
              <div className="col-lg-8 col-12">
                <div className="tm-blog-container">
                  <div className="tm-blog blogitem">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\huangshan\huangshan_trip.webp"
                        alt="blog img"
                      />
                    </div>
                    <div className="tm-blog-bottomside">
                      <div className="tm-blog-tags">
                        <span>
                          <Link to="/News">
                            By: Admin
                            {/* <a href="news-refrigeration.html">Admin</a> */}
                          </Link>
                        </span>
                        <span>Oct 19, 2016</span>
                      </div>
                      <h6>Autumn in October Huangshan trip</h6>
                      <p>
                        October 24 at noon, the staff of four buses from the
                        company collective departure to the destination, arrived
                        in Huizhou InterContinental Hotel evening meal, the
                        second day of collective travel to Huangshan tour, most
                        of the staff brave choice of 7.5 km of mountain
                        Distance, noon collection together to enjoy the letter
                        before the peak, black tiger pine, even the loose pine,
                        monkey view of the sea, the dream pen flower, fairy tale
                        and some interesting scenery, afternoon part of the
                        staff to follow the Dong always continue to visit the
                        bright top, The other part of the staff to follow the
                        total Zhao to enjoy the iconic plants in Huangshan -
                        welcoming pine, 16 km from the mountain road, rugged, we
                        rely on not afraid of fear of tired
                      </p>
                      <blockquote>
                        <p>
                          The spirit to the top of the mountain, enjoy the
                          beautiful welcoming lovers.
                        </p>
                      </blockquote>
                      <p>
                        Huangshan sunrise is also an important part of Huangshan
                        view, the third day looking forward to watch the sunrise
                        employees early bed, with the camera recorded a
                        beautiful sunrise, then set out to visit the West Grand
                        Canyon - dream area, not To have to marvel at the nature
                        of the talent, so steep, so beautiful, there is no
                        artificial modification of a mountain. After the tour of
                        the Grand Canyon, the end of the trip, all the staff
                        returned to Nanjing.
                      </p>
                    </div>

                    <SocialMedia />
                  </div>
                </div>
              </div>
              {/* <!--// Blogs --> */}

              {/* <!-- Sidebar Widgets --> */}
              <div className="col-lg-4 col-12">
                <NewsSideLinks />
              </div>
              {/* <!--// Sidebar Widgets --> */}
            </div>
          </div>
        </div>
        {/* <!--// Blogs Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default HuangshanNews
