import React from "react"
import SideDash from "../components/SideDash"
import image from "./bg-breadcrumb-refrigerating-series.webp"
import { Link } from "react-router-dom"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const RefrigeratingOverview = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Breadcrumb Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb-refrigerating-series.webp"
        data-white-overlay="8"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>Our Products</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>

                {/* <a href="index-refrigeration.html">Home</a> */}
              </li>
              <li>Refrigerating Units</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Products Page Content --> */}
        <div className="tm-section products-page-content tm-padding-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-12 order-1 order-lg-2">
                <div className="tm-shop-products">
                  <div className="row mt-30-reverse">
                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\medium-low-temperature-chiller-1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-medium-low-temp-chiller.html">
                              Medium/Low Temperature{" "}
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\high_temperature_chiller_for_soft_water-1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-high-tempt-chiller.html">
                              High tempt Chiller{" "}
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\air_cooled_screw_chiller-1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-air-cooled-screw-chiller.html">
                              Air-cooled Screw Chiller
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\efficient_screw_water_chilling_unit.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-efficient-screw-chiller.html">
                              Efficient Screw Water Unit
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\insdustrial-screw-chiller-1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-insdustrial-chiller.html">
                              Industrial Water Cooled
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\flooded-type-screw-chiller-1.webp"
                            alt="juding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-flooded-screw-chiller.html">
                              Flooded type Screw
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\evaporative-condser-1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-evaporative-condnser.html">
                              Evaporative Condensing
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\integrated-chiller.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-integrated-water-chiller.html">
                              Integerated Water Unit
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\low-temp-ultra-temp1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-ultralow-temp-refrigerating-unit.html">
                              Low/ Ultra Temperature
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Refrigerating units\water_source_heat_pump_unit_with_screw.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-heat-pump-unit.html">
                              Water Source Heat Pump
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-12 order-2 order-lg-1">
                <div className="widgets widgets-sidebar-shop">
                  {/* <!-- Single Widget --> */}
                  <SideDash />
                  {/* <!--// Single Widget --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Products Page Content --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default RefrigeratingOverview
