import React from "react"
import { NavLink } from "react-router-dom"

const SideDash = () => {
  return (
    <div>
      <div className="single-widget widget-categories">
        <h6 className="widget-title">Categories</h6>
        <ul>
          <li>
            <NavLink
              to="/product-ice-rink"
              style={({ isActive }) =>
                isActive ? { color: "#e2000b" } : { color: "" }
              }
            >
              Ice Rink Series
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/product-air-treatment"
              style={({ isActive }) =>
                isActive ? { color: "#e2000b" } : { color: "" }
              }
            >
              Air Treatment Series
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/product-refrigerating-unit"
              style={({ isActive }) =>
                isActive ? { color: "#e2000b" } : { color: "" }
              }
            >
              Refrigerating Units
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/product-food-refrigeration"
              style={({ isActive }) =>
                isActive ? { color: "#e2000b" } : { color: "" }
              }
            >
              Food Refrigeration
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/product-enviromental-systems"
              style={({ isActive }) =>
                isActive ? { color: "#e2000b" } : { color: "" }
              }
            >
              Environmental Systems
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideDash
