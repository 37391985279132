import React from "react"
import Contacts from "../Contacts"
import image from "./bg-breadcrumb-contact.webp"
import InnerHeader from "../../Header/NavbarInner/InnerHeader"
const ContactDetails = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Heroslider Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}
        
        // data-bgimage="assets/images/bg/bg-breadcrumb-contact.webp"
      ></div>
      {/* <!--// Heeroslider Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        <Contacts />

        {/* <!--// Contact Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default ContactDetails
