import Slider from "react-slick"
import React from "react"
import { Link } from "react-router-dom"
const TestiMonialSlider = () => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    cssEase: "linear",
  }
  return (
    <div>
      <Slider {...settings}>
        <div className="tm-testimonial text-center">
          <span className="tm-testimonial-quoteicon">
            <Link to="/Honor">
              {/* <a href="honors-refrigeration.html"> */}
              <img
                src="assets/images/icons/certificate1.webp"
                alt="quote icon"
              />
              {/* </a> */}
            </Link>
          </span>
        </div>

        <div className="tm-testimonial text-center">
          <span className="tm-testimonial-quoteicon">
            <Link to="/Honor">
              {/* <a href="honors-refrigeration.html"> */}
              <img
                src="assets/images/icons/icon-testimonial-quote-1.webp"
                alt="quote icon"
              />
              {/* </a> */}
            </Link>
          </span>
        </div>

        <div className="tm-testimonial text-center">
          <span className="tm-testimonial-quoteicon">
            <Link to="/Honor">
              {/* <a href="honors-refrigeration.html"> */}
              <img
                src="assets/images/icons/icon-testimonial-quote-2.webp"
                alt="quote icon"
              />
              {/* </a> */}
            </Link>
          </span>
        </div>

        <div className="tm-testimonial text-center">
          <span className="tm-testimonial-quoteicon">
            <Link to="/Honor">
              {/* <a href="honors-refrigeration.html"> */}
              <img
                src="assets/images/icons/icon-testimonial-quote-3.webp"
                alt="quote icon"
              />
              {/* </a> */}
            </Link>
          </span>
        </div>
      </Slider>
    </div>
  )
}

export default TestiMonialSlider
