import React from "react"
import Contacts from "../../../ContactArea/Contacts"
import HonorSideDash from "../Components/HonorSideDash"
import image from "./bg-breadcrumb-honors.webp"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"
const Qualifications = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Heroslider Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb-honors.webp"
      ></div>
      {/* <!--// Heeroslider Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- products --> */}
        <div
          id="tm-products-area"
          className="tm-products-area tm-section tm-padding-section bg-white"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tm-sectiontitle text-center">
                  <h2>Qualifications</h2>
                  <span className="tm-sectiontitle-divider">
                    <img
                      src="assets/images/icons/icon-section-title-divider.webp"
                      alt="divider icon"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row  tm-products-slider tm-slider-arrows">
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\qualification\qualification1.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\qualification\qualification1.webp"
                          alt="Product logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\qualification\qualification2.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\qualification\qualification2.webp"
                          alt="Product logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\qualification\qualification12.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\qualification\qualification12.webp"
                          alt="Product logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\qualification\qualification4.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\qualification\qualification4.webp"
                          alt="Product logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\qualification\qualification5.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\qualification\qualification5.webp"
                          alt="Product logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\qualification\qualification5.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\qualification\qualification5.webp"
                          alt="Product logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\qualification\qualification6.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\qualification\qualification6.webp"
                          alt="Product logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\qualification\qualification7.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\qualification\qualification7.webp"
                          alt="Product logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\qualification\qualification8.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\qualification\qualification8.webp"
                          alt="Product logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
            </div>

            <div className="tm-section products-page-content tm-padding-section bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-12 order-1 order-lg-2">
                    <div className="tm-shop-products">
                      <div className="row mt-30-reverse">
                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\qualification\qualification1.webp"
                              data-caption="Product Zoom Image 1"
                            >
                              <img
                                src="\assets\images\qualification\qualification1.webp"
                                alt="Product logo"
                              />
                            </a>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\qualification\qualification2.webp"
                              data-caption="Product Zoom Image 1"
                            >
                              <img
                                src="\assets\images\qualification\qualification2.webp"
                                alt="Product logo"
                              />
                            </a>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\qualification\qualification12.webp"
                              data-caption="Product Zoom Image 1"
                            >
                              <img
                                src="\assets\images\qualification\qualification12.webp"
                                alt="Product logo"
                              />
                            </a>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\qualification\qualification4.webp"
                              data-caption="Product Zoom Image 1"
                            >
                              <img
                                src="\assets\images\qualification\qualification4.webp"
                                alt="Product logo"
                              />
                            </a>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\qualification\qualification5.webp"
                              data-caption="Product Zoom Image 1"
                            >
                              <img
                                src="\assets\images\qualification\qualification5.webp"
                                alt="Product logo"
                              />
                            </a>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\qualification\qualification6.webp"
                              data-caption="Product Zoom Image 1"
                            >
                              <img
                                src="\assets\images\qualification\qualification6.webp"
                                alt="Product logo"
                              />
                            </a>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-12 order-2 order-lg-1">
                    <div className="widgets widgets-sidebar-shop">
                      <HonorSideDash />

                      {/* <!--// Single Widget --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Promotion Area --> */}
        </div>

        {/* <!-- Call To Action --> */}
        <div
          id="tm-calltoaction-area-2"
          className="tm-cta-2-area tm-section tm-padding-section bg-theme"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8">
                <div className="tm-cta tm-cta-2 text-center">
                  <h2> Sales offices and Services Offices </h2>
                  <p>
                    {" "}
                    Beijing, Nanjing, Jinan, Shijiazhuang, Shenyang, Wuhan,
                    Chongqing, Hefei, Zhengzhou, Nanchang, Shanghai, Guangzhou,
                    Taiyuan, Tianjin, Xiamen, Changchun, Harbin, Chengdu,
                    Guiyang, Xi’an, Hangzhou, Changsha, Nanning, Dalian,
                    Qingdao, Urumchi, Tongliao and other large and medium-sized
                    cities
                  </p>
                  <span className="mail-btn">
                    <a href="mailto:contact@jiuding.com">contact@jiuding.com</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Call To Action --> */}

        {/* <!-- Contact Area --> */}
        <Contacts />
        {/* <!--// Contact Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default Qualifications
