import React from "react"
import SideDash from "../components/SideDash"
import image from "../bg-breadcrumb-ice-rink-series.webp"
// import  "./InlineStyle"

const DetailIceRink = () => {
  return (
    <div>
      {/* <!-- Breadcrumb Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb-ice-rink-series.webp"
        data-white-overlay="8"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>Ice Rink</h2>
            <ul>
              <li>
                <a href="products.html">Our products</a>
              </li>
              <li>
                <a href="product-ice-rink.html">Ice Skating rink</a>
              </li>
              <li>Ice rink Details</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Products Details Page Content --> */}
        <div className="tm-section products-details-page-content tm-padding-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-12 order-1 order-lg-2">
                {/* <!-- Product Details --> */}
                <div className="tm-prodetails">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-10 col-12">
                      {/* <!-- Product Details Images --> */}
                      <div className="tm-prodetails-images">
                        <div className="tm-prodetails-largeimages">
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\products\products edit\ice_rinkers\ice_rink_1.webp"
                              data-caption="Product Zoom Image 1"
                            >
                              <img
                                src="\assets\images\products\products edit\ice_rinkers\ice_rink_1.webp"
                                alt="product"
                              />
                            </a>
                          </div>
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\products\products edit\ice_rinkers\ice_rink_2.webp"
                              data-caption="Product Zoom Image 2"
                            >
                              <img
                                src="\assets\images\products\products edit\ice_rinkers\ice_rink_2.webp"
                                alt="product "
                              />
                            </a>
                          </div>
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\products\products edit\ice_rinkers\ice_rink_3.webp"
                              data-caption="Product Zoom Image 2"
                            >
                              <img
                                src="\assets\images\products\products edit\ice_rinkers\ice_rink_3.webp"
                                alt="product"
                              />
                            </a>
                          </div>
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\products\products edit\ice_rinkers\ice_rink_4.webp"
                              data-caption="Product Zoom Image 2"
                            >
                              <img
                                src="\assets\images\products\products edit\ice_rinkers\ice_rink_4.webp"
                                alt="product"
                              />
                            </a>
                          </div>
                          <div className="tm-prodetails-largeimage">
                            <a
                              data-fancybox="tm-prodetails-imagegallery"
                              href="\assets\images\products\products edit\ice_rinkers\ice_rink_5.webp"
                              data-caption="Product Zoom Image 2"
                            >
                              <img
                                src="\assets\images\products\products edit\ice_rinkers\ice_rink_5.webp"
                                alt="product"
                              />
                            </a>
                          </div>
                        </div>

                        {/* <div className="tm-prodetails-thumbnails">
                                                    <div className="tm-prodetails-thumbnail">
                                                        <img src="\assets\images\products\products edit\ice_rinkers\ice_rink_1.webp"
                                                            alt='product' />
                                                    </div>
                                                    <div className="tm-prodetails-thumbnail">
                                                        <img src="\assets\images\products\products edit\ice_rinkers\ice_rink_2.webp"
                                                            alt='product' />
                                                    </div>
                                                    <div className="tm-prodetails-thumbnail">
                                                        <img src="\assets\images\products\products edit\ice_rinkers\ice_rink_3.webp"
                                                            alt='product' />
                                                    </div>
                                                    <div className="tm-prodetails-thumbnail">
                                                        <img src="\assets\images\products\products edit\ice_rinkers\ice_rink_4.webp"
                                                            alt='product' />
                                                    </div>

                                                    <div className="tm-prodetails-thumbnail">
                                                        <img src="\assets\images\products\products edit\ice_rinkers\ice_rink_5.webp"
                                                            alt='product' />
                                                    </div>


                                                </div> */}
                      </div>

                      {/* <!--// Product Details Images --> */}
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="tm-prodetails-content">
                        <h3 className="tm-prodetails-title">Ice Rink</h3>
                        <ul>
                          <li>
                            <b>Brand Name:</b> Jiuding Refrigeration
                          </li>
                          <li>
                            <b>Product Name:</b> Ice Rink
                          </li>
                          <li>
                            <b>Product Number:</b> 022
                          </li>
                          <li>
                            <b>Retail Price:</b> 0.00
                          </li>
                        </ul>
                        <div className="tm-prodetails-categories">
                          <h6>Categories :</h6>
                          <ul>
                            <li>
                              <a href="ice-rinkers.html">Ice Rink Series</a>
                            </li>
                            <li>
                              <a href="shop.html">Ice Rink</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--// Product Details --> */}

                {/* <!-- Product Details Description & Review --> */}
                <div className="tm-prodetails-desreview tm-padding-section-sm-top">
                  <ul
                    className="nav tm-tabgroup2"
                    id="prodetails"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="prodetails-area1-tab"
                        data-toggle="tab"
                        href="#prodetails-area1"
                        role="tab"
                        aria-controls="prodetails-area1"
                        aria-selected="true"
                      >
                        Description
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="prodetails-content">
                    <div
                      className="tab-pane fade show active"
                      id="prodetails-area1"
                      role="tabpanel"
                      aria-labelledby="prodetails-area1-tab"
                    >
                      <div className="tm-prodetails-description">
                        <p>
                          Modern people pay more and more attention to the
                          health of the entertainment requirements are more and
                          more diversified. Artificial ice skating rink is easy
                          to realize the "ice northward", in the sports venues,
                          shopping malls, schools, community clubs, parks and
                          other places, supporting the appropriate area of ice
                          rink, for figure skating, ice hockey, short track
                          speed skating, To meet people regardless of the
                          season, without geographical restrictions under the
                          conditions of skating or sports training.
                        </p>
                        <p>
                          Skating rink construction standard content includes:
                          ice the various functional layers, ice making system,
                          cooling system, heat recovery system, ice melting
                          system, control systems. Another optional equipment
                          ice rink dehumidifier, ice cars, skates and so on..
                        </p>
                        <p>Key technical index</p>
                        <p>
                          <b>Site Area</b>
                        </p>
                        <div className="detail-index">
                          <table
                            style={{
                              border: "1",
                              cellPadding: "0",
                              cellSpacing: "0",
                              width: "454",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    borderTop: "black 1pt solid",
                                    borderRight: "black 1pt solid",
                                    width: "106.1pt",
                                    backGround: "#f2f2f2",
                                    borderBottom: "black 1pt solid",
                                    paddingBottom: "0cm",
                                    paddingTop: "0cm",
                                    paddingLeft: "5.4pt",
                                    borderLeft: "black 1pt solid",
                                    paddingRight: "5.4pt",
                                    valign: "top",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    borderTop: "black 1pt solid",
                                    borderRight: "black 1pt solid",
                                    width: "49.65pt",
                                    borderBottom: "black 1pt solid",
                                    paddingBottom: "0cm",
                                    paddingTop: "0cm",
                                    paddingLeft: "5.4pt",
                                    borderLeft: "medium none",
                                    paddingRight: "5.4pt",
                                    valign: "top",
                                  }}
                                >
                                  {/* <p style={paragraphStyle}> */}
                                  <p
                                    align="left"
                                    style={{
                                      fontSize: "10.5pt",
                                      fontFamily: "calibri , sans-serif",
                                      textAlign: "left",
                                      margin: "0cm 0cm 0pt",
                                      textJustify: "inter-ideograph",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "9pt",
                                        fontFamily: "times new roman ,serif",
                                      }}
                                    >
                                      m<sup>2</sup>
                                    </span>
                                  </p>
                                </td>
                                <td
                                  style={{
                                    borderTop: "black 1pt solid",
                                    borderRight: "black 1pt solid",
                                    width: "148.8pt",
                                    borderBottom: "black 1pt solid",
                                    paddingBottom: "0cm",
                                    paddingTop: "0cm",
                                    paddingLeft: "5.4pt",
                                    borderLeft: "medium none",
                                    paddingRight: "5.4pt",
                                    valign: "top",
                                  }}
                                >
                                  <p
                                    align="left"
                                    style={{
                                      fontSize: "10.5pt",
                                      fontFamily: "calibri, sans-serif",
                                      textAlign: "left",
                                      margin: "0cm 0cm 0pt",
                                      textJustify: "inter-ideograph",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "9pt",
                                        fontFamily: "times new roman, serif",
                                      }}
                                    >
                                      200~1830
                                    </span>
                                  </p>
                                </td>
                              </tr>

                              {/* <InlineStyle/> */}

                              {/* <tr>
                                                                <td style={{
                                                                    borderTop: "medium none", borderRight: "black 1pt solid", width: "106.1pt", background: "#f2f2f2", borderBottom: "black 1pt solid", paddingBottom: "0cm", paddingTop: "0cm", paddingLeft: "5.4pt", borderLeft: "black 1pt solid", paddingRight: "5.4pt", valign: "top"}}>
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <b><span style="font-size: 9pt; font-family: 宋体;">Standard cooling capacity</span></b></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 49.65pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="66">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">kW</span></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 148.8pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="198">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">100~1120</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 106.1pt; background: #f2f2f2; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: black 1pt solid; padding-right: 5.4pt;" valign="top" width="141">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <b><span style="font-size: 9pt; font-family: 宋体;">Power consumption</span></b></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 49.65pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="66">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">kW</span></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 148.8pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="198">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">50~450</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 106.1pt; background: #f2f2f2; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: black 1pt solid; padding-right: 5.4pt;" valign="top" width="141">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <b><span style="font-size: 9pt; font-family: 宋体;">Ice thickness</span></b></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 49.65pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="66">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">mm</span></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 148.8pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="198">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">30~50</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 106.1pt; background: #f2f2f2; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: black 1pt solid; padding-right: 5.4pt;" valign="top" width="141">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <b><span style="font-size: 9pt; font-family: 宋体;">Ice height difference</span></b></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 49.65pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="66">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">mm</span></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 148.8pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="198">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">&lt;4~6</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 106.1pt; background: #f2f2f2; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: black 1pt solid; padding-right: 5.4pt;" valign="top" width="141">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <b><span style="font-size: 9pt; font-family: 宋体;">Ice surface temperature</span></b></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 49.65pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="66">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 宋体;">℃</span></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 148.8pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="198">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">-3~-7</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 106.1pt; background: #f2f2f2; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: black 1pt solid; padding-right: 5.4pt;" valign="top" width="141">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <b><span style="font-size: 9pt; font-family: 宋体;">Ice rink temperature</span></b></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 49.65pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="66">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 宋体;">℃</span></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 148.8pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="198">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">15±2</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 106.1pt; background: #f2f2f2; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: black 1pt solid; padding-right: 5.4pt;" valign="top" width="141">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <b><span style="font-size: 9pt; font-family: 宋体;">Ice rink humidity</span></b></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 49.65pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="66">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 'times new roman','serif';">%</span></p>
                                                                </td>
                                                                <td style="border-top: medium none; border-right: black 1pt solid; width: 148.8pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: medium none; padding-right: 5.4pt;" valign="top" width="198">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 宋体;">＜</span><span style="font-size: 9pt; font-family: 'times new roman','serif';">70</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="3" style="border-top: medium none; border-right: black 1pt solid; width: 304.55pt; border-bottom: black 1pt solid; padding-bottom: 0cm; padding-top: 0cm; padding-left: 5.4pt; border-left: black 1pt solid; padding-right: 5.4pt;" valign="top" width="406">
                                                                    <p align="left" style="font-size: 10.5pt; font-family: 'calibri','sans-serif'; text-align: left; margin: 0cm 0cm 0pt; text-justify: inter-ideograph;">
                                                                        <span style="font-size: 9pt; font-family: 宋体;">According to the site area, use, supporting the number and type of equipment to determine the relevant data</span></p>
                                                                </td>
                                                            </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--// Product Details Description & Review --> */}

                <div className="tm-similliar-products tm-padding-section-sm-top">
                  <h3 className="small-title">Similar Products</h3>
                  <div className="row tm-similliar-products-slider">
                    {/* <!-- Single Product --> */}
                    <div className="col-12">
                      <div className="tm-product">
                        <div className="box-image">
                          <a href="detail-ice-rink.html">
                            <img
                              src="\assets\images\products\products edit\ice_rinkers\ice_rink_1.webp"
                              alt="ice-rinkers"
                            />
                          </a>
                          <div className="overlay">
                            <a href="detail-ice-rink.html">
                              <div className="text-overlay">
                                Ice skating Rink
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-ice-rink.html">Ice skating Rink</a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-12">
                      <div className="tm-product">
                        <div className="box-image">
                          <a href="detail-sledge.html">
                            <img
                              src="\assets\images\products\products edit\ice_rinkers\sledge_1.webp"
                              alt="ice-rinkers"
                            />
                          </a>
                          <div className="overlay">
                            <a href="detail-sledge.html">
                              <div className="text-overlay">Sledge</div>
                            </a>
                          </div>
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-sledge.html">Sledge</a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-12">
                      <div className="tm-product">
                        <div className="box-image">
                          <a href="detail-unit-ice-rink.html">
                            <img
                              src="\assets\images\products\products edit\ice_rinkers\unit_ice_rink.webp"
                              alt="ice-rinkers"
                            />
                          </a>
                          <div className="overlay">
                            <a href="detail-unit-ice-rink.html">
                              <div className="text-overlay">
                                Unit Specially for Ice Rink
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-unit-ice-rink.html">
                              Unit Specially for Ice Rink
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}
                  </div>

                  
                </div>
              </div>

              <div className="col-lg-3 col-12 order-2 order-lg-1">
                <div className="widgets widgets-sidebar-shop">
                  {/* <!-- Single Widget --> */}
                  {/* <div className="single-widget widget-categories">
                                        <h6 className="widget-title">Categories</h6>
                                        <ul>
                                            <li><a href="product-ice-rink.html">Ice Rink Series</a></li>
                                            <li><a href="product-air-treatment.html">Air Treatment Series</a></li>
                                            <li><a href="product-refrigerating-unit.html">Refrigerating Units</a></li>
                                            <li><a href="product-food-refrigeration.html">Food Refrigeration</a></li>
                                            <li><a href="product-enviromental-systems.html">Environmental Systems</a></li>

                                        </ul>
                                    </div> */}
                  <SideDash />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default DetailIceRink
