import React, { useState, useEffect } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import { Button } from "./Styles";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(document.documentElement.scrollTop > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => toggleVisible();
    window.addEventListener("scroll", handleScroll);
    // console.log("addEventListener");

    return () => {
      window.removeEventListener("scroll", handleScroll);
      // console.log("removeEventListener");
    };
  }, []); // The empty dependency array ensures that this effect runs once when the component mounts

  return (
    <Button>
      <FaArrowCircleUp
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      />
    </Button>
  );
};

export default ScrollButton;
