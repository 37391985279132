import React from "react";

const CallArea2 = () => {
  return (
    <div
      id="projects"
      className="tm-cta-2-area tm-section tm-padding-section-index bg-theme"
    >
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8">
            <div className="tm-cta tm-cta-2 text-center">
              <h2>Our Honorable Clients</h2>
              <h5>Our Feeling Joy Service you the Best</h5>
              <span className="mail-btn">
                <a href="afridi@Jiudingref.com">afridi@Jiudingref.com</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallArea2;
