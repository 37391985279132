import React from "react"
import ProjectHeroslider from "../../components/ProjectHeroslider"
import SideLinkProjects from "../../components/SideLinkProjects"
import ContactNums from "../../components/ContactTwoNums"
import SaleOffers from "../../components/SaleOffers"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const AutoMobile = () => {
  return (
    <div>
      <div>
        <InnerHeader/>
        {/* <!-- Heroslider Area --> */}
        <ProjectHeroslider />
        {/* <!--// Heeroslider Area --> */}

        {/* <!-- Main Content --> */}
        <main className="page-content">
          {/* <!-- products --> */}
          <div
            id="tm-products-area"
            className="tm-products-area tm-section tm-padding-section bg-white"
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="tm-sectiontitle text-center">
                    <h2>Our Projects</h2>
                    <span className="tm-sectiontitle-divider">
                      <img
                        src="assets/images/icons/icon-section-title-divider.webp"
                        alt="divider icon"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row tm-products-slider tm-slider-arrows">
                {/*  */}
                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_suzuki.webp"
                          data-caption="Suzuki Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_suzuki.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_tata.webp"
                          data-caption="Tata Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_tata.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  */}
                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_toyota.webp"
                          data-caption="Toyota Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_toyota.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_volvo.webp"
                          data-caption="Volvo Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_volvo.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_doosan.webp"
                          data-caption="Doosan Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_doosan.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_faw.webp"
                          data-caption="Faw Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_faw.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_ford.webp"
                          data-caption="Ford Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_ford.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_foton.webp"
                          data-caption="Foton Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_foton.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_foton_bejing.webp"
                          data-caption="Foton Bejing Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_foton_bejing.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_geeley.webp"
                          data-caption="Geeley Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_geeley.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_anakai.webp"
                          data-caption="Anakai Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_anakai.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_baicmotor.webp"
                          data-caption="Baic Auto"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_baicmotor.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_byo.webp"
                          data-caption="Byo"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_byo.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_changan.webp"
                          data-caption="changan"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_changan.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_chery.webp"
                          data-caption="chery"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_chery.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_das.webp"
                          data-caption="das"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_das.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_dfev.webp"
                          data-caption="dfev"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_dfev.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_dfm.webp"
                          data-caption="dfm"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_dfm.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="tm-prodetails-images">
                    <div className="tm-prodetails-largeimages">
                      <div className="tm-prodetails-largeimage">
                        <a
                          data-fancybox="tm-prodetails-imagegallery"
                          href="\assets\images\projects\pro-auto-mobile\auto_geeley.webp"
                          data-caption="geeley"
                        >
                          <img
                            src="\assets\images\projects\pro-auto-mobile\auto_geeley.webp"
                            alt="product img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tm-section products-page-content tm-padding-section bg-white">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9 col-12 order-1 order-lg-2">
                      <div className="tm-shop-products">
                        <div className="row mt-30-reverse">
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_suzuki.webp"
                                alt="Suzuki Auto"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Suzuki Auto Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_tata.webp"
                                alt="Tata Auto"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Tata Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_toyota.webp"
                                alt="Toyota"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Toyota Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_volvo.webp"
                                alt="volvo"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Volvo Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_foton_bejing.webp"
                                alt="Beijing_Foton_Automobile"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Beijing Foton Automobile{" "}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_doosan.webp"
                                alt="doosan"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Doosan Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_faw.webp"
                                alt="faw"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  <i
                                    className="fa fa-window-maximize"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_ford.webp"
                                alt="ford"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Ford Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_foton.webp"
                                alt="foton"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Foton Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_foton_bejing.webp"
                                alt="Bejing"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Beijing Foton Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_geeley.webp"
                                alt="geeley Automobile"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Geeley Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_anakai.webp"
                                alt="Anakai"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Anakai Automobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_baicmotor.webp"
                                alt="baic"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Baic AutoMobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_byo.webp"
                                alt="byo"
                              />
                              <div className="overlay">
                                <div className="text-overlay">
                                  Byo AutoMobile
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_changan.webp"
                                alt="auto"
                              />
                              <div className="overlay">
                                <div className="text-overlay">Changan</div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_chery.webp"
                                alt="auto"
                              />
                              <div className="overlay">
                                <div className="text-overlay">Chery</div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_das.webp"
                                alt="auto"
                              />
                              <div className="overlay">
                                <div className="text-overlay">Das</div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_dfev.webp"
                                alt="auto"
                              />
                              <div className="overlay">
                                <div className="text-overlay">Dfev</div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_dfm.webp"
                                alt="auto"
                              />
                              <div className="overlay">
                                <div className="text-overlay">DFM</div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_dfev.webp"
                                alt="auto"
                              />
                              <div className="overlay">
                                <div className="text-overlay">Dfev</div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                            <div className="box-image">
                              <img
                                src="\assets\images\projects\pro-auto-mobile\auto_geeley.webp"
                                alt="auto"
                              />
                              <div className="overlay">
                                <div className="text-overlay">Geeley</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 order-2 order-lg-1">
                      <div className="widgets widgets-sidebar-shop">
                        <SideLinkProjects />
                        {/* <!--// Single Widget --> */}

                        {/* <!-- Single Widget --> */}

                        {/* <!--// Single Widget --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Call To Action --> */}
          <div
            id="tm-calltoaction-area-2"
            className="tm-cta-2-area tm-section tm-padding-section bg-theme"
          >
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8">
                  <SaleOffers />
                </div>
              </div>
            </div>
          </div>
          {/* <!--// Call To Action --> */}

          {/* <!-- Contact Area --> */}

          <ContactNums />
          {/* <!--// Contact Area --> */}
        </main>
        {/* <!--// Main Content --> */}
      </div>
    </div>
  )
}

export default AutoMobile
