import { useState } from "react";
import { Tab, Nav } from "react-bootstrap";

export default function App() {
  const [currentTab, setCurrentTab] = useState("first");

  return (
    <Tab.Container
      defaultActiveKey="first"
      activeKey={currentTab}
      onSelect={(key) => setCurrentTab(key)}
    >
        <Nav variant="pills" className="row" >
          <Nav.Item>
            <Nav.Link eventKey="first">Pakistan Address</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">China Address</Nav.Link>
          </Nav.Item>
        </Nav>
<br />
        <Tab.Content>
          <Tab.Pane eventKey="first">
           
            <p>Pakistan Address: 107 Sughra Tower,F-11 Markaz Islamabad Pakistan<br />Email:
              info@jiudingref.com.pk<br />Phone: +92-51-2224822</p>
          </Tab.Pane>

          <Tab.Pane eventKey="second">
            <p>
              Factory Address: No.8 West Longzhong Road, Luhe Economic Development
              Zone, Nanjing, Jiangsu, China.<br />Email: afridi@Jiudingref.com<br />Phone:
              0086-1525-1731109
            </p>
          </Tab.Pane>
        </Tab.Content>
    </Tab.Container>
  );
}
