import React from "react"
import SideDash from "../components/SideDash"
import image from "./bg-breadcrumb-enviroment-series.webp"
import { Link } from "react-router-dom"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const EnvironmentalSystemsOverView = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Breadcrumb Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb-enviroment-series.webp"
        data-white-overlay="8"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>Our Products</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>

                {/* <a href="index-refrigeration.html">Home</a> */}
              </li>
              <li>Environmental Climate Simulation Systems</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Products Page Content --> */}
        <div className="tm-section products-page-content tm-padding-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-12 order-1 order-lg-2">
                <div className="tm-shop-products">
                  <div className="row mt-30-reverse">
                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="/assets/images/products/products edit/Environmental_series/vehicle/vehicle_test_enviroment_system.webp"
                            alt="juding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-vehicle-test-enviroment.html">
                              Vehichle Test Enviroment
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="/assets/images/products/products edit/Environmental_series/engine/engine_test_enviroment_system_4.webp"
                            alt="jiuiding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-engine-enviroment.html">
                              Engine Enviroment
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="/assets/images/products/products edit/Environmental_series/defense/defense_test_enviroment_system_1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-military-enviroment-simulation.html">
                              Defense Enviroment
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="/assets/images/products/products edit/Environmental_series/large-circulating/large_circulating_system_1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-large-circulating-water-system.html">
                              Large Circulating Water System{" "}
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}
                  </div>
                </div>
                {/* <!--// Single Product -- */}

                {/* <!-- Single Product --> */}
              </div>
              <div className="col-lg-3 col-12 order-2 order-lg-1">
                <div className="widgets widgets-sidebar-shop">
                  {/* <!-- Single Widget --> */}
                  <SideDash />
                  {/* <!--// Single Widget --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Products Page Content --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default EnvironmentalSystemsOverView
