import React from "react"
import SocialMedia from "../components/SocialMedia"
import NewsSideLinks from "../components/NewsSideLinks"
import image from "./bg-breadcrumb.webp"
import { Link } from "react-router-dom"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"
const PharmaceuticalNews = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Breadcrumb Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb.webp"
        data-white-overlay="4"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>The 41st China International Pharmaceutical Machinery Fair</h2>
            <ul>
              <li>
                <Link to="/News">
                  News
                  {/* <a href="news-refrigeration.html">News</a> */}
                </Link>
              </li>
              <li>
                <Link to="/News-Pharmaceutical">
                  {/* <a href="news-pharmaceutical.html"> */}
                  The 41st China International Pharmaceutical Machinery Fair
                  {/* </a> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Blogs Area --> */}
        <div className="tm-blogs-area tm-padding-section bg-white">
          <div className="container">
            <div className="row no-gutters">
              {/* <!-- Blogs --> */}
              <div className="col-lg-8 col-12">
                <div className="tm-blog-container">
                  <div className="tm-blog blogitem">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\n41china\n41china-first-image.webp"
                        alt="blog img"
                      />
                    </div>
                    <div className="tm-blog-bottomside">
                      <div className="tm-blog-tags">
                        <span>
                          <Link to="/News">
                            By: Admin
                            {/* <a href="news-refrigeration.html">Admin</a> */}
                          </Link>
                        </span>
                        <span>Jun 03, 2011</span>
                      </div>
                      <h6>
                        The 41st China International Pharmaceutical Machinery
                        Fair
                      </h6>
                      <p>
                        Organized by the China Pharmaceutical Equipment Industry
                        Association of China International Pharmaceutical
                        Machinery Expo since 1991 has been successfully held 40
                        sessions. The fair is held twice a year in spring and
                        autumn. Exhibits include raw materials machinery and
                        equipment, all kinds of preparation machinery,
                        pharmaceutical crushing machinery, Pieces of machinery,
                        pharmaceutical water equipment, pharmaceutical packaging
                        machinery, drug testing equipment, other pharmaceutical
                        machinery \ equipment and packaging materials eight
                        categories of pharmaceutical machinery and equipment.
                        These devices are mainly related to pharmaceuticals
                        (including Chinese and Western medicine, biological
                        products and veterinary drugs) industrial, health
                        products, household chemicals and food industry. The
                        exhibition area of ​​6-8 million square meters,
                        exhibitors 700-800, more than 4,000 standard booths,
                        exhibits more than 8,000 sets of visitors from home and
                        abroad more than 60,000 people. Since 2003, the show has
                        been one of the highlights of the China Ministry of
                        Commerce.
                      </p>
                      <blockquote>
                        <p>
                          Organized by the China Pharmaceutical Equipment
                          Industry Association of China International
                          Pharmaceutical Machinery Expo since 1991 has been
                          successfully held 40 sessions.
                        </p>
                      </blockquote>
                      <p>
                        The 41st China International Pharmaceutical Machinery
                        Fair (Spring 2011) will be held from May 29th to June
                        1st, 2011 at the Shenyang International Exhibition
                        Center. I am invited to participate in the company, not
                        only learn from other manufacturers of the merits, more
                        importantly, also attract many domestic and foreign
                        merchants to visit, purchase and technical exchanges.
                      </p>
                    </div>

                    <SocialMedia />
                  </div>
                </div>
              </div>
              {/* <!--// Blogs --> */}

              {/* <!-- Sidebar Widgets --> */}
              <div className="col-lg-4 col-12">
                <NewsSideLinks />
              </div>
              {/* <!--// Sidebar Widgets --> */}
            </div>
          </div>
        </div>
        {/* <!--// Blogs Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default PharmaceuticalNews
