import React from "react"
import { Link } from "react-router-dom"

import image from "../bg-breadcrumb-ice-rink-series.webp"
import SideDash from "../components/SideDash"
import ProductIceSkating from "../components/ProductIceSkating"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const IceSkatingRink = () => {
  return (
    <>
      {/* <!-- Breadcrumb Area --> */}
      <InnerHeader/>
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb-ice-rink-series.webp"
        data-white-overlay="8"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>Our Products</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
                {/* <a href="index-refrigeration.html">Home</a> */}
              </li>
              <li>Ice Skating Rink</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Products Page Content --> */}
        <div className="tm-section products-page-content tm-padding-section bg-white">
          <div className="container">
            <div className="row">
              <ProductIceSkating />
              <div className="col-lg-3 col-12 order-2 order-lg-1">
                <div className="widgets widgets-sidebar-shop">
                  {/* <!-- Single Widget --> */}
                  <SideDash />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Products Page Content --> */}
      </main>
    </>
  )
}

export default IceSkatingRink
