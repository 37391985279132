import React from "react"
import { Link } from "react-router-dom"
const SideLinkProjects = () => {
  return (
    <div>
      <div className="single-widget widget-categories">
        <h6 className="widget-title">CATEGORIES</h6>

        <ul>
          <li>
            <Link to="/Pakistan">
              Pakistan
              {/* <a href="project-pak.html">Pakistan</a> */}
            </Link>
          </li>
          <li>
            <Link to="/AutoMobile">
              Auto Mobile
              {/* <a href="project-auto-mobile.html"></a> */}
            </Link>
          </li>
          <li>
            <Link to="/IceRink">
              Ice Rink
              {/* <a href="project-ice-rink.html">Ice Rink</a> */}
            </Link>
          </li>
          <li>
            <Link to="/Metallurgy">
              Metallurgy
              {/* <a href="project-metallurgy.html">Metallurgy</a> */}
            </Link>
          </li>
          <li>
            <Link to="/Electronics">
              Electronics
              {/* <a href="project-electronics.html">Electronics </a> */}
            </Link>
          </li>
          <li>
            <Link to="/Food&Beverages">
              Food and Beverages
              {/* <a href="project-food.html">Food and Beverages </a> */}
            </Link>
          </li>
          <li>
            <Link to="/Medicine&Medical">
              Medicine and Medical
              {/* <a href="project-medicine.html">Medicine and Medical </a> */}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideLinkProjects
