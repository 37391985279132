import React from "react"
import { Link } from "react-router-dom"

const ProductArea = () => {
  return (
    <div
      id="products"
      className=" tm-section tm-padding-section-index bg-white"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="tm-sectiontitle text-center">
              <h2>Our Latest Products</h2>
              <span className="tm-sectiontitle-divider">
                <img
                  src="assets/images/icons/icon-section-title-divider.webp"
                  alt="divider icon"
                />
              </span>
              <p> Provide Customers With More Excellent Product and Services</p>
            </div>
          </div>
        </div>
        <div className="row ">
          {/* <!-- Single Product --> */}
          <div className="col">
            <div className="tm-product">
              <div className="box-image">
                <Link to="/Products">
                  {/* <Link to="/product-ice-rink"> */}
                  {/* <a href="products-refrigeration.html"> */}
                  <img
                    src="assets/images/products/ice-rinkers.webp"
                    alt="ice-rinkers"
                  />
                  {/* </a> */}
                </Link>

                <div className="overlay">
                  <div className="text-overlay">
                    <Link to="/Products">
                      {/* <Link to="/product-ice-rink"> */}
                      {/* <a href="products-refrigeration.html"> */}
                      Ice Skating Rink
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="tm-product-bottom">
                <h6>
                  <Link to="/Products">
                    {/* <Link to="/product-ice-rink"> */}
                    {/* <a href="products-refrigeration.html"> */}
                    Ice Skating Rink
                    {/* </a> */}
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          {/* <!--// Single Product --> */}
          <div className="col">
            <div className="tm-product">
              <div className="box-image">
                <Link to="/Products">
                  {/* <a href="products-refrigeration.html"> */}
                  <img
                    src="assets/images/products/Air-purification.webp"
                    alt="jiuding product"
                  />
                  {/* </a> */}
                </Link>
                <div className="overlay">
                  <Link to="/Products">
                    {/* <a href="products-refrigeration.html"> */}
                    <div className="text-overlay">Air Treatment</div>
                    {/* </a> */}
                  </Link>
                </div>
              </div>
              <div className="tm-product-bottom">
                <h6>
                  <Link to="/Products">
                    {/* <a href="products-refrigeration.html"> */}
                    Air Treatment
                    {/* </a> */}
                  </Link>
                </h6>
              </div>
            </div>
          </div>

          {/* <!-- Single Product --> */}
          <div className="col">
            <div className="tm-product">
              <div className="box-image">
                <Link to="/Products">
                  {/* <a href="products-refrigeration.html"> */}
                  <img
                    src="assets/images/products/refrigerating-units-2.webp"
                    alt="jiuding product"
                  />
                  {/* </a> */}
                </Link>

                <div className="overlay">
                  <Link to="/Products">
                    {/* <a href="products-refrigeration.html"> */}
                    <div className="text-overlay">Refrigerating Units</div>
                    {/* </a> */}
                  </Link>
                </div>
              </div>
              <div className="tm-product-bottom">
                <h6>
                  <Link to="/Products">
                    {/* <a href="products-refrigeration.html"> */}
                    Refrigerating Units
                    {/* </a> */}
                  </Link>
                </h6>
              </div>
            </div>
          </div>

          {/* <!-- Single Product --> */}
          <div className="col">
            <div className="tm-product">
              <div className="box-image">
                <Link to="/Products">
                  {/* <a href="products-refrigeration.html"> */}
                  <img
                    src="assets/images/products/Food-refrigeration-2.webp"
                    alt="jiuding product"
                  />
                  {/* </a> */}
                </Link>
                <div className="overlay">
                  <Link to="/Products">
                    {/* <a href="products-refrigeration.html"> */}
                    <div className="text-overlay">Food Refrigeration</div>
                    {/* </a> */}
                  </Link>
                </div>
              </div>
              <div className="tm-product-bottom">
                <h6>
                  <Link to="/Products">
                    {/* <a href="products-refrigeration.html"> */}
                    Food Refrigeration
                    {/* </a> */}
                  </Link>
                </h6>
              </div>
            </div>
          </div>

          {/* <!-- Single Product --> */}
          <div className="col">
            <div className="tm-product">
              <div className="box-image">
                <Link to="/Products">
                  {/* <a href="products-refrigeration.html"> */}
                  <img
                    src="assets/images/products/enviroment-2.webp"
                    // src="assets/images/products/enviroment-2.webp"

                    alt="jiuding product"
                  />
                  {/* </a> */}
                </Link>

                <div className="overlay">
                  <Link to="/Products">
                    {/* <a href="products-refrigeration.html"> */}
                    <div className="text-overlay">
                      Environmental Climate Simulation System
                    </div>
                    {/* </a> */}
                  </Link>
                </div>
              </div>
              <div className="tm-product-bottom">
                <h6>
                  <Link to="/Products">
                    {/* <a href="products-refrigeration.html"> */}
                    Environmental
                    {/* </a> */}
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          {/* <!--// Single Product --> */}
        </div>
      </div>
    </div>
  )
}

export default ProductArea
