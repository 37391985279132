import React from "react"
import { Link } from "react-router-dom"
const NewsSideLinks = () => {
  return (
    <div>
      <div className="widgets widgets-sidebar">
        {/* <!-- Single Widget --> */}
        <div className="single-widget widget-recentpost">
          <h6 className="widget-title">Recent Posts</h6>
          <ul>
            <li>
              <Link to="/News-Huangshan" className="widget-recentpost-image">
                {/* <a href="news-huangshan.html" className="widget-recentpost-image"> */}
                <img
                  src="\assets\images\news\huangshan\huangshan_trip_thumbnail.webp"
                  alt=" thumbnail"
                />
                {/* </a> */}
              </Link>
              <div className="widget-recentpost-content">
                <h6>
                  <Link to="/News-Huangshan">
                    {/* <a href="news-huangshan.html"> */}
                    Autumn in October Huangshan trip
                    {/* </a> */}
                  </Link>
                </h6>
                <span>Jan 15, 2019</span>
              </div>
            </li>
            <li>
              <Link to="/News-Shanghai" className="widget-recentpost-image">
                {/* 
              <a
                href="news-shanghai_automotive.html"
                className="widget-recentpost-image"
              > */}
                <img
                  src="\assets\images\news\automotive\auto-motive-thumbnail.webp"
                  alt=" thumbnail"
                />
                {/* </a> */}
              </Link>
              <div className="widget-recentpost-content">
                <h6>
                  <Link to="/News-Shanghai">
                    {/* <a href="news-shanghai_automotive.html"> */}
                    Shanghai Automotive Testing and Quality Control
                    {/* </a> */}
                  </Link>
                </h6>
                <span>Sep 16, 2011</span>
              </div>
            </li>
            <li>
              <Link to="/News-Mid-Autumn" className="widget-recentpost-image">
                {/* <a
                href="news-mid-autumn.html"
                className="widget-recentpost-image"
              > */}
                <img
                  src="\assets\images\news\autumnfestival\autumn-festival-thumbnail.webp"
                  alt="blog thumbnail"
                />
                {/* </a> */}
              </Link>
              <div className="widget-recentpost-content">
                <h6>
                  <Link to="/News-Mid-Autumn">
                    {/* <a href="news-mid-autumn.html"> */}
                    Mid-Autumn Festival, National Day holiday
                    {/* </a> */}
                  </Link>
                </h6>
                <span>Sep 08, 2011</span>
              </div>
            </li>
            <li>
              <Link to="/News-Tea-Party" className="widget-recentpost-image">
                {/* <a
                href="news-jiuding-tea-party.html"
                className="widget-recentpost-image"
              > */}
                <img
                  src="\assets\images\news\tea-party\teaparty-thumbnail.webp"
                  alt="blog thumbnail"
                />
                {/* </a> */}
              </Link>
              <div className="widget-recentpost-content">
                <h6>
                  <Link to="/News-Tea-Party">
                    {/* <a href="news-jiuding-tea-party.html"> */}
                    Jiuding New Year tea party
                    {/* </a> */}
                  </Link>
                </h6>
                <span>Oct 19, 2016</span>
              </div>
            </li>
            <li>
              <Link to="/News-Qingdao" className="widget-recentpost-image">
                {/* <a href="news-qingdao.html" className="widget-recentpost-image"> */}
                <img
                  src="\assets\images\news\qindgo\qindigo-thumbnail.webp"
                  alt="blog thumbnail"
                />
                {/* </a> */}
              </Link>
              <div className="widget-recentpost-content">
                <h6>
                  <Link to="/News-Qingdao">
                    {/* <a href="news-qingdao.html"> */}
                    3rd China (Qingdao) International API and Intermediates
                    Exhibition
                    {/* </a> */}
                  </Link>
                </h6>
                <span>July 25, 2011</span>
              </div>
            </li>
            <li>
              <Link
                to="/News-Pharmaceutical"
                className="widget-recentpost-image"
              >
                {/* 
              <a
                href="news-pharmaceutical.html"
                className="widget-recentpost-image"
              > */}
                <img
                  src="\assets\images\news\n41china\n41china-thumbnail.webp"
                  alt="blog thumbnail"
                />
                {/* </a> */}
              </Link>
              <div className="widget-recentpost-content">
                <h6>
                  <Link to="/News-Pharmaceutical">
                    {/* <a href="news-pharmaceutical.html"> */}
                    The 41st China International Pharmaceutical Machinery Fair
                    {/* </a> */}
                  </Link>
                </h6>
                <span>Jun 03, 2011</span>
              </div>
            </li>
          </ul>
        </div>
        {/* <!--// Single Widget --> */}
        {/* <!-- Popular Tags --> */}
        <div className="single-widget widget-tags">
          <h6 className="widget-title">Popular Products</h6>
          <ul>
            <li>
              <Link to="/product-ice-rink">Ice Rink</Link>

              {/* <a href="product-ice-rink.html">Ice Rink</a> */}
            </li>
            <li>
              <Link to="/product-air-treatment">Air Purification </Link>

              {/* <a href="product-air-treatment.html">Air Purification</a> */}
            </li>
            <li>
              <Link to="/product-refrigerating-unit">Refrigerating Units</Link>

              {/* <a href="product-refrigerating-unit.html">Refrigerating Units</a> */}
            </li>
            <li>
              <Link to="/product-food-refrigeration">Food Refrigeration</Link>

              {/* <a href="product-food-refrigeration.html">Food Refrigeration</a> */}
            </li>
            <li>
              <Link to="/product-enviromental-systems">
                {/* <a href="product-enviromental-systems.html"> */}
                Environmental Services
                {/* </a> */}
              </Link>
            </li>
          </ul>
        </div>
        {/* <!--// Popular Tags --> */}
      </div>
    </div>
  )
}

export default NewsSideLinks
