import React from "react"
import image from "./bg-breadcrumb-products.webp"

import ProductArea2 from "../ProductArea2"
import Contacts from "../../ContactArea/Contacts"
import InnerHeader from "../../Header/NavbarInner/InnerHeader"

const OverView = () => {
  return (
    <div>
      <InnerHeader/>
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb-products.webp"
      ></div>

      <ProductArea2 />
      {/* < !--Main Content-- > */}
      <main className="page-content">
        {/* <!-- Call To Action --> */}
        <div
          id="tm-calltoaction-area-2"
          className="tm-cta-2-area tm-section tm-padding-section bg-theme"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8">
                <div className="tm-cta tm-cta-2 text-center">
                  <h2> Sales and Services Offices </h2>
                  <p>
                    {" "}
                    Beijing, Nanjing, Jinan, Shijiazhuang, Shenyang, Wuhan,
                    Chongqing, Hefei, Zhengzhou, Nanchang, Shanghai, Guangzhou,
                    Taiyuan, Tianjin, Xiamen, Changchun, Harbin, Chengdu,
                    Guiyang, Xi’an, Hangzhou, Changsha, Nanning, Dalian,
                    Qingdao, Urumchi, Tongliao and other large and medium-sized
                    cities
                  </p>
                  <span className="mail-btn">
                    <a href="afridi@Jiudingref.com.pk">
                      afridi@Jiudingref.com.pk
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Contacts />
      </main>
    </div>
  )
}

export default OverView
