import TestiMonialSlider from "./TestiMonialSlider";


const Testimonials = () => {


  return (
    <div
      id="honor"
      className=" tm-section tm-padding-section-index bg-white">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="tm-sectiontitle text-center">
              <h2>Our Rewards</h2>
              <span className="tm-sectiontitle-divider">
                <img
                  src="assets/images/icons/icon-section-title-divider.webp"
                  alt="divider icon"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="tm-testimonial-slider tm-slider-arrows">
              <div className="testimo-slider">
                <TestiMonialSlider />
                <div className="btn-wrap">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >

  );
};

export default Testimonials;
