import React from "react"
import { Link } from "react-router-dom"
const SocialMedia = () => {
  return (
    <div>
      {" "}
      <div className="blogitem-share">
        <h5 className="blogitem-share-title">Share With:</h5>
        <ul>
          <li className="facebook">
            <Link to="#facebook">
              {/* <a href="#facebook"> */}
              <i className="ion-social-facebook"></i> Facebook
              {/* </a> */}
            </Link>
          </li>
          <li className="twitter">
            <Link to="#twitter">
              {/* <a href="#twitter"> */}
              <i className="ion-social-twitter"></i> Twitter
              {/* </a> */}
            </Link>
          </li>
          <li className="pinterest">
            <Link to="#pinterest">
              {/* <a href="#pinterest"> */}
              <i className="ion-social-pinterest-outline"></i> Pinterest
              {/* </a> */}
            </Link>
          </li>
          <li className="linkedin">
            <Link to="#linkedin">
              {/* <a href="#linkedin"> */}
              <i className="ion-social-linkedin-outline"></i> Linkedin
              {/* </a> */}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SocialMedia
