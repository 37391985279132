import React from "react"
import Contacts from "../../../ContactArea/Contacts"
import SaleOffers from "../../components/SaleOffers"
import ProjectHeroslider from "../../components/ProjectHeroslider"
import SideLinkProjects from "../../components/SideLinkProjects"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const MedicineMedical = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Heroslider Area --> */}
      <ProjectHeroslider />
      {/* <!--// Heeroslider Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Features --> */}

        {/* <!--// Features --> */}

        {/* <!-- About --> */}

        {/* <!--// About --> */}

        {/* <!-- products --> */}
        <div
          id="tm-products-area"
          className="tm-products-area tm-section tm-padding-section bg-white"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tm-sectiontitle text-center">
                  <h2>Our Projects</h2>
                  <span className="tm-sectiontitle-divider">
                    <img
                      src="assets/images/icons/icon-section-title-divider.webp"
                      alt="divider icon"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row tm-honor-slider tm-slider-arrows">
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-medicine\China_resources_pharmaceutical_ltd.webp"
                        data-caption="Chinese_Academy_of_Metrology.webp"
                      >
                        <img
                          src="\assets\images\projects\pro-medicine\China_resources_pharmaceutical_ltd.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-medicine\Euclidean_Pharmaceutical_Group.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-medicine\Euclidean_Pharmaceutical_Group.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-medicine\Jiangxi_people_kang_pharmaceutical.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-medicine\Jiangxi_people_kang_pharmaceutical.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}

              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-medicine\Jun_ye_pharma.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-medicine\Jun_ye_pharma.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-medicine\Zhejiang_Pharmaceutical_Co_Ltd.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-medicine\Zhejiang_Pharmaceutical_Co_Ltd.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tm-section products-page-content tm-padding-section bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-12 order-1 order-lg-2">
                    <div className="tm-shop-products">
                      <div className="row mt-30-reverse">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-medicine\Euclidean_Pharmaceutical_Group.webp"
                              alt="Euclidean_Pharmaceutical_Group"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Euclidean Pharmaceutical Group
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-medicine\China_resources_pharmaceutical_ltd.webp"
                              alt="China resources pharmaceutical ltd"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                China Resources pharmaceutical
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-medicine\Jiangxi_people_kang_pharmaceutical.webp"
                              alt="Jiangxi people kang pharmaceutical"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Jiangxi People Kang Pharmaceutical
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-medicine\Jun_ye_pharma.webp"
                              alt="Jun_ye_pharma"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Jun ye Pharmaceutical
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-medicine\puzheng_medicine.webp"
                              alt="Puzheng medicine"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Puzheng medicine
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-medicine\tianjin_tasly_pharmaceutical_ltd.webp"
                              alt="tianjin_tasly_pharmaceutical_ltd"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Tianjin Tasly Pharmaceutical Ltd
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-medicine\Zhejiang_Kyushu_Pharmaceutical.webp"
                              alt="Zhejiang_Kyushu_Pharmaceutical"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Zhejiang Kyushu Pharmaceutical
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-medicine\Zhejiang_Pharmaceutical_Co_Ltd.webp"
                              alt="Zhejiang_Pharmaceutical_Co_Ltd."
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Zhejiang Pharmaceutical Ltd.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product -- */}
                    {/* <div className="tm-pagination mt-50">
                      <ul>
                        <li>
                          <a href="shop-leftsidebar.html">&lt; Prev</a>
                        </li>
                        <li className="is-active">
                          <a href="shop.html">1</a>
                        </li>
                        <li>
                          <a href="shop-leftsidebar.html">2</a>
                        </li>
                        <li>
                          <a href="shop-leftsidebar.html">3</a>
                        </li>
                        <li>
                          <a href="shop-leftsidebar.html">Next &gt;</a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="col-lg-3 col-12 order-2 order-lg-1">
                    <div className="widgets widgets-sidebar-shop">
                      <SideLinkProjects />
                      {/* <!--// Single Widget --> */}

                      {/* <!-- Single Widget --> */}

                      {/* <!--// Single Widget --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Promotion Area --> */}
        </div>

        {/* <!-- Call To Action --> */}
        <div
          id="tm-calltoaction-area-2"
          className="tm-cta-2-area tm-section tm-padding-section bg-theme"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8">
                <SaleOffers />
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Call To Action --> */}

        {/* <!-- Contact Area --> */}
        <Contacts />
        {/* <!--// Contact Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default MedicineMedical
