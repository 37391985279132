import React from "react"
import SocialMedia from "../components/SocialMedia"
import NewsSideLinks from "../components/NewsSideLinks"
import image from "./bg-breadcrumb.webp"
import { Link } from "react-router-dom"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"
const QingdaoNews = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Breadcrumb Area --> */}

      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        // data-bgimage="assets/images/bg/bg-breadcrumb.webp"
        data-bgimage={`url(${image})`}

        data-white-overlay="4"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>
              3rd China (Qingdao) International API and Intermediates Exhibition
            </h2>
            <ul>
              <li>
                <Link to="/News">
                  News
                  {/* <a href="news-refrigeration.html">News</a> */}
                </Link>
              </li>
              <li>
                <Link to="/News-Qingdao">
                  {/* <a href="news-qingdao.html"> */}
                  3rd China (Qingdao) International API and Intermediates
                  Exhibition
                  {/* </a> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Blogs Area --> */}
        <div className="tm-blogs-area tm-padding-section bg-white">
          <div className="container">
            <div className="row no-gutters">
              {/* <!-- Blogs --> */}
              <div className="col-lg-8 col-12">
                <div className="tm-blog-container">
                  <div className="tm-blog blogitem">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\qindgo\qindigo.webp"
                        alt="blog img"
                      />
                    </div>
                    <div className="tm-blog-bottomside">
                      <div className="tm-blog-tags">
                        <span>
                          <Link to="/News">
                            By: Admin
                            {/* <a href="news-refrigeration.html">Admin</a> */}
                          </Link>
                        </span>
                        <span>July 25, 2011</span>
                      </div>
                      <h6>
                        3rd China (Qingdao) International API and Intermediates
                        Exhibition
                      </h6>

                      <p>
                        (Qingdao) International Pharmaceutical Machinery and
                        Packaging Equipment Exhibition (PI & PM), the third
                        China (Qingdao) International Drugstore and
                        Intermediates Exhibition 2011 and the 3rd China
                        (Qingdao) International Pharmaceutical Machinery and
                        Packaging Equipment Exhibition (PI & PM) will be held
                        from August 11th to 13th, The annual PI & PM has a new
                        breakthrough every year. It has formed the only
                        professional and influential industry event in North
                        China. The company will bring high quality products,
                        advanced technology and excellent service to the
                        exhibition. Companies learn from each other, welcome all
                        my colleagues to visit the exhibition.
                      </p>
                      <blockquote>
                        <p>
                          Our company will be exhibiting at the 3rd China
                          (Qingdao) International API and Intermediates
                          Exhibition
                        </p>
                      </blockquote>
                    </div>

                    <SocialMedia />
                  </div>
                </div>
              </div>
              {/* <!--// Blogs --> */}

              {/* <!-- Sidebar Widgets --> */}
              <div className="col-lg-4 col-12">
                <NewsSideLinks />
              </div>
              {/* <!--// Sidebar Widgets --> */}
            </div>
          </div>
        </div>
        {/* <!--// Blogs Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default QingdaoNews
