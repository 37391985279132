import React from "react"
import SideDash from "../components/SideDash"
import image from "./bg-breadcrumb-food-series.webp"
import { Link } from "react-router-dom"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const FoodRefrigerationOverView = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Breadcrumb Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb-food-series.webp"
        data-white-overlay="8"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>Our Products</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>

                {/* <a href="index-refrigeration.html">Home</a> */}
              </li>
              <li>Cooled Storage/Rooms</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Products Page Content --> */}
        <div className="tm-section products-page-content tm-padding-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-12 order-1 order-lg-2">
                <div className="tm-shop-products">
                  <div className="row mt-30-reverse">
                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Food_refrigeration\cooled-storage-fisrt-page.webp"
                            alt="Jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-cooled-storage.html">
                              Cooled Storage
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Food_refrigeration\evaporative-condensing-type-medium-low-temp.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-evaporative-condnser-screw-chiller.html">
                              {" "}
                              Evaporative Condensing{" "}
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Food_refrigeration\medium-low-temperature-chiller-1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-medium-temp-chiller.html">
                              Medium Refrigerating
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}

                    {/* <!-- Single Product --> */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                      <div className="tm-product">
                        <div className="tm-product-top">
                          <img
                            src="\assets\images\products\products edit\Food_refrigeration\evaporative-condser-1.webp"
                            alt="jiuding product"
                          />
                        </div>
                        <div className="tm-product-bottom">
                          <h6>
                            <a href="detail-evaporative-type-condnser-unit.html">
                              {" "}
                              Evaporator Condeser
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* <!--// Single Product --> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-12 order-2 order-lg-1">
                <div className="widgets widgets-sidebar-shop">
                  {/* <!-- Single Widget --> */}
                  <SideDash />

                  {/* <!--// Single Widget --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Products Page Content --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default FoodRefrigerationOverView
