import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import TestIng from "./TestIng";

const Header = () => {
  const [scroll, setScroll] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY < 250);
    };

    window.addEventListener("scroll", handleScroll);
    // console.log("Header scroll");

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
      // console.log("Header scroll removeEventListener");
    };
  }, []);

  return (
    <div id="Home" className={scroll ? "tm-header" : "tm-header is-sticky"}>
      <div className="tm-header-topside">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="tm-header-topside-infoleft">
                <li>
                  <b>
                    <i className="ion-android-mail"></i> Email:{" "}
                  </b>
                  <Link to="mailto:info@jiudingref.com.pk">
                    info@jiudingref.com.pk
                  </Link>
                </li>
                <li>
                  <b>
                    <i className="ion-android-call"></i> Contact:{" "}
                  </b>
                  <Link to="tel:+92-51-2224822 ">+92-51-2224822</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul className="tm-header-topside-inforight tm-ratingbox">
                <div className="tm-ratingbox">
                  <Link to="http://www.jiudingref.com/">
                    <img src="\assets\images\icons\star2.webp" alt="chinese" />
                  </Link>
                  <Link to="http://www.jiudingref.com/">
                    <span className="is-active">
                      <b>&nbsp;CHN</b>
                    </span>
                  </Link>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <TestIng />
    </div>
  );
};

export default Header;
