import React from "react"
import Contacts from "../../../ContactArea/Contacts"
import image from "./bg-breadcrumb-news.webp"
import { Link } from "react-router-dom"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const News = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Heroslider Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb-news.webp"
      ></div>
      {/* <!--// Heeroslider Area --> */}
      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- service --> */}
        <div
          id="tm-blogs-area"
          className="tm-blog-area tm-section tm-padding-section bg-grey"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tm-sectiontitle text-center">
                  <h2>Latest News</h2>
                  <span className="tm-sectiontitle-divider">
                    <img
                      src="assets/images/icons/icon-section-title-divider.webp"
                      alt="divider icon"
                    />
                  </span>
                  <p>Company News</p>
                </div>
              </div>
            </div>
            <div className="row blog-slider-active tm-slider-dots-2">
              {/* <!-- Single Blog --> */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="tm-blog">
                  <Link to="/News-Huangshan">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\huangshan\huangshan_trip.webp"
                        alt="blog img"
                      />
                      {/* <a href="news-huangshan.html"> */}
                      {/* <i className="ion-link"> </i> */}
                      {/* </a> */}
                    </div>
                  </Link>

                  <div className="tm-blog-bottomside">
                    <div className="tm-blog-tags">
                      <span>
                        <Link to="/News">
                          By: Admin{" "}
                          {/* By: <a href="news-refrigeration.html">Admin</a> */}
                        </Link>
                      </span>
                      <span>Oct 19, 2016</span>
                    </div>
                    <h6>
                      <Link to="/News-Huangshan">
                        {/* <a href="news-huangshan.html"> */}
                        Autumn in October Huangshan trip
                        {/* </a> */}
                      </Link>
                    </h6>
                    <p>
                      In order to thank employees for the company's development
                      process to make unremitting efforts and selfless
                      dedication; also to promote staff exchanges, strengthen
                      the team between the seamless docking, enhance friendship
                      and enhance cohesion;
                    </p>
                    <Link to="/News-Huangshan" className="tm-readmore">
                      {/* <a href="news-huangshan.html" className="tm-readmore"> */}
                      Read more <i className="ion-arrow-right-c"></i>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!--// Single Blog --> */}
              {/* <!-- Single Blog --> */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="tm-blog">
                  <Link to="/News-Shanghai">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\automotive\auto-motive2.webp"
                        alt="blog logo"
                      />
                      {/* <a href="news-shanghai_automotive.html"> */}
                      {/* <i className="ion-link"></i> */}
                      {/* </a> */}
                    </div>
                  </Link>
                  <div className="tm-blog-bottomside">
                    <div className="tm-blog-tags">
                      <span>
                        <Link to="/News">
                          By: Admin
                          {/* <a href="news-refrigeration.html">Admin</a> */}
                        </Link>
                      </span>
                      <span>Sep 16, 2011</span>
                    </div>
                    <h6>
                      <Link to="/News-Shanghai">
                        {/* <a href="news-shanghai_automotive.html"> */}
                        Shanghai Automotive Testing and Quality Control Expo
                        {/* </a> */}
                      </Link>
                    </h6>
                    <p>
                      Leading the world-related industries, "the sixth car test
                      and quality control Expo" in September 14, 2011 in
                      Shanghai Everbright Convention and Exhibition Center was
                      held.
                    </p>
                    <Link to="/News-Shanghai" className="tm-readmore">
                      {/* <a
                      href="news-shanghai_automotive.html"
                      className="tm-readmore"
                      > */}
                      Read more <i className="ion-arrow-right-c"></i>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!--// Single Blog --> */}
              {/* <!-- Single Blog --> */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="tm-blog">
                  <Link to="/News-Mid-Autumn">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\autumnfestival\autumn-festival.webp"
                        alt="blog logo"
                      />

                      {/* <a href="news-mid-autumn.html">
                      <i className="ion-link"></i>
                    </a> */}
                    </div>
                  </Link>
                  <div className="tm-blog-bottomside">
                    <div className="tm-blog-tags">
                      <span>
                        <Link to="/News">
                          By: Admin
                          {/* By: <a href="news-refrigeration.html">Admin</a> */}
                        </Link>
                      </span>
                      <span>Sep 08, 2011</span>
                    </div>
                    <h6>
                      <Link to="/News-Mid-Autumn">
                        {/* <a href="news-mid-autumn.html"> */}
                        Mid-Autumn Festival, National Day holiday notice
                        {/* </a> */}
                      </Link>
                    </h6>
                    <p>
                      According to the "General Office of the State Council
                      announced in 2011 part of the holiday arrangements
                      notice", combined with the company's actual situation,is
                      the 2011 Mid-Autumn Festival, National Day holiday
                      schedule is as follows:
                    </p>
                    <Link to="/News-Mid-Autumn" className="tm-readmore">
                      {/* <a href="news-mid-autumn.html" className="tm-readmore"> */}
                      Read more <i className="ion-arrow-right-c"></i>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!--// Single Blog --> */}
              {/* <!-- Single Blog --> */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="tm-blog">
                  <Link to="/News-Tea-Party">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\tea-party\teaparty1.webp"
                        alt="blog logo"
                      />

                      {/* <a href="news-jiuding-tea-party.html">
                      <i className="ion-link"></i>
                    </a> */}
                    </div>
                  </Link>
                  <div className="tm-blog-bottomside">
                    <div className="tm-blog-tags">
                      <span>
                        <Link to="/News">
                          By: Admin
                          {/* By: <a href="news-refrigeration.html">Admin</a> */}
                        </Link>
                      </span>
                      <span>Oct 19, 2016</span>
                    </div>
                    <h6>
                      <Link to="/News-Tea-Party">
                        {/* <a href="news-jiuding-tea-party.html"> */}
                        Jiuding New Year tea party
                        {/* </a> */}
                      </Link>
                    </h6>
                    <p>
                      Yu rabbit old age, Jinlong welcome the new spring. January
                      15, Jiuding refrigeration in the Luhe East Hall held a
                      grand welcome spring tea party and annual recognition of
                      the General Assembly.
                    </p>
                    <Link to="/News-Tea-Party" className="tm-readmore">
                      {/* <a
                      href="news-jiuding-tea-party.html"
                      className="tm-readmore"
                    > */}
                      Read more <i className="ion-arrow-right-c"></i>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!--// Single Blog --> */}
              {/* <!-- Single Blog --> */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="tm-blog">
                  <Link to="/News-Qingdao">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\qindgo\qindigo.webp"
                        alt="blog img"
                      />
                      {/* <a href="news-qingdao.html">
                      <i className="ion-link"></i>
                    </a> */}
                    </div>
                  </Link>
                  <div className="tm-blog-bottomside">
                    <div className="tm-blog-tags">
                      <span>
                        <Link to="/News">
                          By: Admin
                          {/* By: <a href="news-refrigeration.html">Admin</a> */}
                        </Link>
                      </span>
                      <span>July 25, 2011</span>
                    </div>
                    <h6>
                      <Link to="/News-Qingdao">
                        {/* <a href="news-qingdao.html"> */}
                        3rd China (Qingdao) International API and Intermediates
                        Exhibition
                        {/* </a> */}
                      </Link>
                    </h6>
                    <p>
                      (Qingdao) International Pharmaceutical Machinery and
                      Packaging Equipment Exhibition (PI & PM), the third China
                      (Qingdao) International Drugstore .
                    </p>
                    <Link to="/News-Qingdao" className="tm-readmore">
                      {/* <a href="news-qingdao.html" className="tm-readmore"> */}
                      Read more <i className="ion-arrow-right-c"></i>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!--// Single Blog --> */}
              {/* <!-- Single Blog --> */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="tm-blog">
                  <Link to="/News-Pharmaceutical">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\n41china\n41china-first-image.webp"
                        alt="blog img"
                      />
                      {/* <a href="news-pharmaceutical.html"> */}
                      {/* <i className="ion-link"></i> */}
                      {/* </a> */}
                    </div>
                  </Link>
                  <div className="tm-blog-bottomside">
                    <div className="tm-blog-tags">
                      <span>
                        <Link to="/News">
                          By: Admin
                          {/* By: <a href="news-refrigeration.html"></a> */}
                        </Link>
                      </span>
                      <span>Jun 03, 2011</span>
                    </div>
                    <h6>
                      <Link to="/News-Pharmaceutical">
                        {/* <a href="news-pharmaceutical.html"> */}
                        The 41st China International Pharmaceutical Machinery
                        Fair
                        {/* </a> */}
                      </Link>
                    </h6>
                    <p>
                      Organized by the China Pharmaceutical Equipment Industry
                      Association of China International Pharmaceutical
                      Machinery Expo since 1991 has been successfully..
                    </p>
                    <Link to="/News-Pharmaceutical" className="tm-readmore">
                      {/* <a href="news-pharmaceutical.html" className="tm-readmore"> */}
                      Read more <i className="ion-arrow-right-c"></i>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!--// Single Blog --> */}
            </div>
          </div>
        </div>

        {/* <!-- Sale office --> */}
        <div
          id="tm-calltoaction-area-2"
          className="tm-cta-2-area tm-section tm-padding-section bg-theme"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8">
                <div className="tm-cta tm-cta-2 text-center">
                  <h2> Sales and Services Offices </h2>
                  <p>
                    Beijing, Nanjing, Jinan, Shijiazhuang, Shenyang, Wuhan,
                    Chongqing, Hefei, Zhengzhou, Nanchang, Shanghai, Guangzhou,
                    Taiyuan, Tianjin, Xiamen, Changchun, Harbin, Chengdu,
                    Guiyang, Xi’an, Hangzhou, Changsha, Nanning, Dalian,
                    Qingdao, Urumchi, Tongliao and other large and medium-sized
                    cities
                  </p>
                  <span className="mail-btn">
                    <a href="afridi@Jiudingref.com.pk">
                      afridi@Jiudingref.com.pk
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Call To Action --> */}
        {/* <!-- Contact Area --> */}
        <Contacts />
        {/* <!--// Contact Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default News
