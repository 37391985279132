import React from "react"
import SideLinkProjects from "../../components/SideLinkProjects"
import ProjectHeroslider from "../../components/ProjectHeroslider"
import ContactNums from "../../components/ContactTwoNums"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"
const PakistanProject = () => {
  return (
    <div>
      <InnerHeader/>
      <ProjectHeroslider />
      {/* <!-- Main Content --> */}

      <main className="page-content">
        {/* <!-- products --> */}
        <div
          id="tm-products-area"
          className="tm-products-area tm-section tm-padding-section bg-white"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tm-sectiontitle text-center">
                  <h2>Our Projects</h2>
                  <span className="tm-sectiontitle-divider">
                    <img
                      src="assets/images/icons/icon-section-title-divider.webp"
                      alt="divider icon"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row tm-products-slider tm-slider-arrows">
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\ghurkihospital.webp"
                        data-caption="Ghurki Hospital"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\ghurkihospital.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\engropolymer.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\engropolymer.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\yaqeen.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\yaqeen.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\spdpakistan.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\spdpakistan.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\laksongroup.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\laksongroup.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        href="\assets\images\projects\pro-pakistan\technopharma.webp"
                        data-fancybox="tm-prodetails-imagegallery"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\technopharma.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\Al-jadeed.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\Al-jadeed.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\Dimond.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\Dimond.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\KK-Rice.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\KK-Rice.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\Mondelez.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\Mondelez.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\Shama.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\Shama.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\Sindh-Secretariat.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\Sindh-Secretariat.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\Techino.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\Techino.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\VictoryPipe.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\VictoryPipe.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\WinPipe.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\WinPipe.webp"
                          alt="product "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-pakistan\ZKB.webp"
                        data-caption="Product Zoom Image 1"
                      >
                        <img
                          src="\assets\images\projects\pro-pakistan\ZKB.webp"
                          alt="product"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>

            <div className="tm-section products-page-content tm-padding-section bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-12 order-1 order-lg-2">
                    <div className="tm-shop-products">
                      <div className="row mt-30-reverse">
                        {/*  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\ghurkihospital.webp"
                              alt="Ghurki Hospital"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Ghurki Hospital
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\engropolymer.webp"
                              alt="engropolymer"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Engro Polymers</div>
                            </div>
                          </div>
                        </div>

                        {/*  */}
                        {/*  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\laksongroup.webp"
                              alt="laksongroup"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Lakson Tobacco</div>
                            </div>
                          </div>
                        </div>

                        {/*  */}
                        {/*  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\spdpakistan.webp"
                              alt="spdpakistan"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Pakistan Defense
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*  */}
                        {/*  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\technopharma.webp"
                              alt="technopharma"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Techno Pharma</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\yaqeen.webp"
                              alt="yaqeen"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Yaqeen Art Press
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\ZKB.webp"
                              alt="ZKB"
                            />
                            <div className="overlay">
                              <div className="text-overlay">ZKB</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\WinPipe.webp"
                              alt="WinPipe"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Win Pipe</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\VictoryPipe.webp"
                              alt="VictoryPipe"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Victory Pipe</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\Techino.webp"
                              alt="Techino"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Techno Pharma </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\Sindh-Secretariat.webp"
                              alt="Sindh-Secretariat"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Sindh Secretariat
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\Shama.webp"
                              alt="Shama"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Shama banaspati
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\Mondelez.webp"
                              alt="Mondelez"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Mondelez International
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\KK-Rice.webp"
                              alt="KK-Rice"
                            />
                            <div className="overlay">
                              <div className="text-overlay">KK Rice Mill</div>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        {/*  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\Dimond.webp"
                              alt="Dimond"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Dimond Group</div>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-pakistan\Al-jadeed.webp"
                              alt="Al-jadeed"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Al Jadeed Super Market
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-12 order-2 order-lg-1">
                    <div className="widgets widgets-sidebar-shop">
                      <SideLinkProjects />
                      {/* side link projects */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Promotion Area --> */}
          </div>

          {/* <!-- Call To Action --> */}
          <div
            id="tm-calltoaction-area-2"
            className="tm-cta-2-area tm-section tm-padding-section bg-theme"
          >
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8">
                  <div className="tm-cta tm-cta-2 text-center">
                    <h2> Sales offices and Services Offices </h2>
                    <p>
                      Beijing, Nanjing, Jinan, Shijiazhuang, Shenyang, Wuhan,
                      Chongqing, Hefei, Zhengzhou, Nanchang, Shanghai,
                      Guangzhou, Taiyuan, Tianjin, Xiamen, Changchun, Harbin,
                      Chengdu, Guiyang, Xi’an, Hangzhou, Changsha, Nanning,
                      Dalian, Qingdao, Urumchi, Tongliao and other large and
                      medium-sized cities
                    </p>
                    <span className="mail-btn">
                      <a href="mailto:contact@jiuding.com">
                        contact@jiuding.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Contact Area --> */}
          <ContactNums />
          {/* <!--// Contact Area --> */}
        </div>
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default PakistanProject
