import React from "react"
import { Link } from "react-router-dom"

const ProductIceSkating = () => {
  return (
    <>
      <div className="col-lg-9 col-12 order-1 order-lg-2">
        <div className="tm-shop-products">
          <div className="row mt-30-reverse">
            {/* <!-- Single Product --> */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
              <div className="tm-product">
                <div className="box-image">
                  <Link to="/detail-ice-rink">
                    {/* <a href="detail-ice-rink.html"> */}
                    <img
                      src="\assets\images\products\products edit\ice_rinkers\ice_rink_1.webp"
                      alt="ice-rinkers"
                    />
                    {/* </a> */}
                  </Link>
                  <div className="overlay">
                    <Link to="/detail-ice-rink">
                      {/* <a href="detail-ice-rink.html"> */}
                      <div className="text-overlay">Ice Skating Rink</div>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
                <div className="tm-product-bottom">
                  <Link to="/detail-ice-rink">
                    <h6>
                      {/* <a href="detail-ice-rink.html"> */}
                      Ice Skating Rink
                      {/* </a> */}
                    </h6>
                  </Link>
                </div>
              </div>
            </div>

            {/* <!--// Single Product --> */}

            {/* <!-- Single Product --> */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
              <div className="tm-product">
                <div className="box-image">
                  <Link to="/detail-sledge">
                    {/* <a href="detail-sledge.html"> */}
                    <img
                      src="\assets\images\products\products edit\ice_rinkers\sledge_1.webp"
                      alt="ice-rinkers"
                    />
                    {/* </a> */}
                  </Link>
                  <div className="overlay">
                    <Link to="/detail-sledge">
                      {/* <a href="detail-sledge.html"> */}
                      <div className="text-overlay">Sledge</div>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
                <div className="tm-product-bottom">
                  <h6>
                    <Link to="/detail-sledge">
                      Sledge
                      {/* <a href="detail-sledge.html">Sledge</a> */}
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
            {/* <!--// Single Product --> */}

            {/* <!-- Single Product --> */}

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
              <div className="tm-product">
                <div className="box-image">
                  <Link to="/detail-unit-ice-rink">
                    {/* <a href="detail-unit-ice-rink.html"> */}
                    <img
                      src="\assets\images\products\products edit\ice_rinkers\unit_ice_rink.webp"
                      alt="ice-rinkers"
                    />
                    {/* </a> */}
                  </Link>
                  <div className="overlay">
                    <Link to="/detail-unit-ice-rink">
                      {/* <a href="detail-unit-ice-rink.html"> */}
                      <div className="text-overlay">
                        Unit Specially for Ice Rink
                      </div>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
                <div className="tm-product-bottom">
                  <h6>
                    <Link to="/detail-unit-ice-rink">
                      {/* <a href="detail-unit-ice-rink.html"> */}
                      Unit Specially for Ice Rink
                      {/* </a> */}
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
            {/* <!--// Single Product --> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductIceSkating
