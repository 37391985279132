import React from "react"
import SocialMedia from "../components/SocialMedia"
import image from "./bg-breadcrumb.webp"
import NewsSideLinks from "../components/NewsSideLinks"
import { Link } from "react-router-dom"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"


const MidAutumNews = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Breadcrumb Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb.webp"
        data-white-overlay="4"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>Mid-Autumn Festival, National Day holiday</h2>
            <ul>
              <li>
                <Link to="/News">
                  News
                  {/* <a href="news-refrigeration.html">News</a> */}
                </Link>
              </li>
              <li>
                <Link to="/News-Mid-Autumn">
                  {/* <a href="news-mid-autumn.html"> */}
                  Mid-Autumn Festival National Day
                  {/* </a> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Blogs Area --> */}
        <div className="tm-blogs-area tm-padding-section bg-white">
          <div className="container">
            <div className="row no-gutters">
              {/* <!-- Blogs --> */}
              <div className="col-lg-8 col-12">
                <div className="tm-blog-container">
                  <div className="tm-blog blogitem">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\autumnfestival\autumn-festival.webp"
                        alt="blog img"
                      />
                    </div>
                    <div className="tm-blog-bottomside">
                      <div className="tm-blog-tags">
                        <span>
                          <Link to="/News">
                            By: Admin
                            {/* <a href="news-refrigeration.html">Admin</a> */}
                          </Link>
                        </span>
                        <span>Oct 19, 2016</span>
                      </div>
                      <h6>Mid-Autumn Festival, National Day holiday</h6>

                      <p>
                        According to the "General Office of the State Council
                        announced in 2011 part of the holiday arrangements
                        notice", combined with the company's actual situation,
                        is the 2011 Mid-Autumn Festival, National Day holiday
                        schedule is as follows: September 10, 2011 (Saturday),
                        11th (Sunday), 12 (Monday) holiday three days, September
                        13 (Tuesday) to work. Monday, October 1, 2011, Saturday,
                        Sunday, Sunday, Sunday, Sunday, Sunday, Sunday, Sunday
                        Duanxiu, a total of seven days, October 8 (Saturday), 9
                        (Sunday) to work.
                      </p>

                      <blockquote>
                        <p>Mid-Autumn Festival, National Day holiday</p>
                      </blockquote>
                      <p>
                        1. In the factory staff, during the holidays if
                        overtime, according to the company's production tasks by
                        direct leadership co-ordination arrangements, and
                        submitted to the deputy general manager for approval. 2.
                        Outside the staff, leave time can be arranged according
                        to the progress of the project. 3. During the holiday,
                        the relevant personnel to do security work. Nanjing long
                        tripod refrigeration air - conditioning Equipment Co.,
                        Ltd. September 6, 2011
                      </p>
                    </div>

                    <SocialMedia />

                    {/* <!-- Blogitem Comments --> */}

                    {/* <!--// Blogitem Comments --> */}

                    {/* <!-- Blogitem Commentbox --> */}

                    {/* <!--// Blogitem Commentbox --> */}
                  </div>
                </div>
              </div>
              {/* <!--// Blogs --> */}

              {/* <!-- Sidebar Widgets --> */}
              <div className="col-lg-4 col-12">
                <NewsSideLinks />
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Blogs Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}
 export default MidAutumNews