import React from "react"
import SideDash from "../components/SideDash"
import image from "./bg-breadcrumb-air-treat-series.webp"
import { Link } from "react-router-dom"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const AirTreatmentProducts = () => {
  return (
    <>
    <InnerHeader/>
      {/* <!-- Wrapper --> */}
      <div className="wrapper">
        {/* <!-- Breadcrumb Area --> */}
        <div
          className="tm-breadcrumb-area tm-padding-section"
          style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

          // data-bgimage="assets/images/bg/bg-breadcrumb-air-treat-series.webp"
          data-white-overlay="8"
        >
          <div className="container">
            <div className="tm-breadcrumb">
              <h2>Our Products</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>

                  {/* <a href="index-refrigeration.html">Home</a> */}
                </li>
                <li>Air Treatment Series</li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!--// Breadcrumb Area --> */}

        {/* <!-- Main Content --> */}
        <main className="page-content">
          {/* <!-- Products Page Content --> */}
          <div className="tm-section products-page-content tm-padding-section bg-white">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 col-12 order-1 order-lg-2">
                  <div className="tm-shop-products">
                    <div className="row mt-30-reverse">
                      {/* <!-- Single Product --> */}
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                        <div className="tm-product">
                          <div className="tm-product-top">
                            <img
                              src="\assets\images\products\products edit\Air_treatment_series\combined_air_handling_unit.webp"
                              alt="jiuding product"
                            />
                          </div>
                          <div className="tm-product-bottom">
                            <h6>
                              <a href="detail-combined-air-handling.html">
                                Air Handling Unit
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* <!--// Single Product --> */}

                      {/* <!-- Single Product --> */}
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                        <div className="tm-product">
                          <div className="tm-product-top">
                            <img
                              src="\assets\images\products\products edit\Air_treatment_series\constant_temperature.webp"
                              alt="jiuding product"
                            />
                          </div>
                          <div className="tm-product-bottom">
                            <h6>
                              <a href="detail-constant-temperature.html">
                                Constant Temperature
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* <!--// Single Product --> */}

                      {/* <!-- Single Product --> */}
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                        <div className="tm-product">
                          <div className="tm-product-top">
                            <img
                              src="\assets\images\products\products edit\Air_treatment_series\conventional_and_pipe_type_dehumidification.webp"
                              alt="jiuding product"
                            />
                          </div>
                          <div className="tm-product-bottom">
                            <h6>
                              <a href="detail-conventional-dehumdification.html">
                                Pipe Type Dehumidifier
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* <!--// Single Product --> */}

                      {/* <!-- Single Product --> */}
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                        <div className="tm-product">
                          <div className="tm-product-top">
                            <img
                              src="\assets\images\products\products edit\Air_treatment_series\direct_expansion.webp"
                              alt="jiuding product"
                            />
                          </div>
                          <div className="tm-product-bottom">
                            <h6>
                              <a href="detail-direct-expansion.html">
                                Direct Expansion
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* <!--// Single Product --> */}

                      {/* <!-- Single Product --> */}
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                        <div className="tm-product">
                          <div className="tm-product-top">
                            <img
                              src="\assets\images\products\products edit\Air_treatment_series\purification_unit_air_conditioner.webp"
                              alt="jiuding product"
                            />
                          </div>
                          <div className="tm-product-bottom">
                            <h6>
                              <a href="detail-purification-unit.html">
                                Purification Unit
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* <!--// Single Product --> */}

                      {/* <!-- Single Product --> */}
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                        <div className="tm-product">
                          <div className="tm-product-top">
                            <img
                              src="\assets\images\products\products edit\Air_treatment_series\unit_air_conditioner2.webp"
                              alt="jiuding product"
                            />
                          </div>
                          <div className="tm-product-bottom">
                            <h6>
                              <a href="detail-unit-airconditioner.html">
                                Unit Air Conditioner
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* <!--// Single Product --> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 order-2 order-lg-1">
                  <div className="widgets widgets-sidebar-shop">
                    <SideDash />

                    {/* <!--// Single Widget --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--// Products Page Content --> */}
        </main>
      </div>
    </>
  )
}

export default AirTreatmentProducts
