// import Bounce from "react-reveal/Bounce"
import { Link } from "react-router-dom"
const Services = () => {
  return (
    <div
      id="services"
      className=" tm-section tm-padding-section-index bg-white"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="tm-sectiontitle text-center">
              <h2>Our Services</h2>
              <span className="tm-sectiontitle-divider">
                <img
                  src="assets/images/icons/icon-section-title-divider.webp"
                  alt="divider icon"
                />
              </span>
              <p>
                Our company based on years of practical experience, The use of
                perfect Interactive After-sales service system.
              </p>
            </div>
          </div>
        </div>
        {/* <Bounce left> */}
        <div className="row mt-30-reverse">
          <div className="col-lg-4 col-md-6 col-12 mt-30">
            <Link to="/Services">
              {/* <a href="service-refrigeration.html"> */}
              <div className="tm-service text-center tm-revealblock">
                <div className="tm-service-icon">
                  <img
                    src="assets/images/icons/icon-service-customer.webp"
                    alt="feature icon"
                  />
                </div>
                <div className="tm-service-content">
                  <h5> Customer </h5>
                  <p style={{ color: "#000000" }}>
                    We ensure the best quality of services to our users.
                  </p>
                </div>
              </div>
              {/* </a> */}
            </Link>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-30">
            <Link to="/Services">
              {/* <a href="service-refrigeration.html"> */}
              <div className="tm-service text-center tm-revealblock">
                <div className="tm-service-icon">
                  <img
                    src="assets/images/icons/icon-service-warranty.webp"
                    alt="feature icon"
                  />
                </div>
                <div className="tm-service-content">
                  <h5>Warranty </h5>
                  <p style={{ color: "#000000" }}>
                    Provide 100% warranty period of Service to our users
                  </p>
                </div>
              </div>
              {/* </a> */}
            </Link>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-30">
            <Link to="/Services">
              {/* <a href="service-refrigeration.html"> */}
              <div className="tm-service text-center tm-revealblock">
                <div className="tm-service-icon">
                  <img
                    src="assets/images/icons/icon-service-emergency.webp"
                    alt="feature icon"
                  />
                </div>
                <div className="tm-service-content">
                  <h5>Emergency Response </h5>
                  <p style={{ color: "#000000" }}>
                    24h service hotline, At any time on the user's emergency
                    requirements .
                  </p>
                </div>
              </div>
              {/* </a> */}
            </Link>
          </div>
        </div>
        {/* </Bounce> */}
      </div>
    </div>
  )
}

export default Services
