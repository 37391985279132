import Carousel from "./Carousel "

const BrandsLogo = () => {
  return (
    // call to action area
    <div className=" tm-section tm-padding-section bg-grey">
      <div className="container">
        <div className="tm-brandlogo-slider">
          <div className="tm-brandlogo-area tm-section tm-padding-section bg-grey">
            <div className="container">
              <Carousel />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandsLogo
