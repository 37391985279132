import React from "react"
import image from "./bg-breadcrumb-about.webp"
import InnerHeader from "../../Header/NavbarInner/InnerHeader"
import Contacts from "../../ContactArea/Contacts"
// import { motion } from "framer-motion"

const AboutDetail2 = () => {
  return (
    <>
    {/* // <motion.div> */}
      <InnerHeader />
      <div>
        {/* <!-- Heroslider Area --> */}
        <div
          className="tm-breadcrumb-area tm-padding-section"
          style={{ backgroundImage: `url(${image})` }}
          data-bgimage={`url(${image})`}
        ></div>
 

 {/* <img
          className="tm-breadcrumb-area tm-padding-section"
          src="assets/images/bg/bg-breadcrumb-about.webp"
          // style={{ backgroundImage: `url(${image})` }}
          // data-bgimage="assets/images/bg/bg-breadcrumb-about.webp"
          alt=" logo cover"
        /> */}


        {/* <!--// Heeroslider Area --> */}

        {/* <!-- Main Content --> */}
        <main className="page-content">
          {/* <!-- About Company --> */}
          <div className="tm-funfact-area tm-section tm-padding-section bg-grey">
            <div className="container">
              <div className="col-lg-12">
                <div className="about-content">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                      <div className="tm-sectiontitle text-center">
                        <h2>About our company you can trust</h2>
                        <span className="tm-sectiontitle-divider">
                          <img
                            src="assets/images/icons/icon-section-title-divider.webp"
                            alt="divider icon"
                            loading="lazy"
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <p className="text-justify">
                    Nanjing Jiuding Refrigeration & Air Conditioning Equipment
                    Co., Ltd., formerly known as Nanjing Jianmao Refrigeration &
                    Air Conditioning Equipment Co., Ltd., was founded in 2003
                    and our company a professional Chinese manufacturer that
                    engages in refrigeration and air treatment equipment and a
                    high-tech enterprise that integrates science, industry and
                    trade. We take creating pleasant, natural room of life and
                    meticulous, exquisite production environment as our bounden
                    duty and commit ourselves to the development and production
                    of refrigeration and air treatment facilities in such realms
                    as industry, business and military filed.
                  </p>
                  <p className="text-justify">
                    All our refrigeration and air conditioning facilities are
                    made by mature technology and exhibit reliable quality. Now
                    our products have come into over ten categories and have
                    come into wide use in such fields as metallurgy, machinery,
                    petroleum, chemical engineering, steel, solar photovoltaic,
                    automobile, pharmaceuticals, electronics, textile, and food
                    as well as in hospitals, gymnasiums, hotels, office
                    buildings, shopping centers and other places.
                  </p>
                  <p className="text-justify">
                    We also have two subsidiaries, that is, Nanjing Jiuding
                    Refrigeration & Air Conditioning Equipment Co., Ltd., Sales
                    Branch Company and Nanjing Jiuding Refrigeration & Heating
                    Equipment Engineering Co., Ltd. which are capable of
                    offering coordinated service, including system design,
                    equipment supply, project management, installation
                    debugging, maintenance, upkeep, etc. We also boast a team of
                    highly qualified technical personnel who have rich
                    professional knowledge and working experience. Our sales and
                    service center made up of roughly 30 offices in large and
                    medium-sized cities nationwide has put into place a sound
                    marketing and sales system, which allows us to meet all the
                    customer needs before sale, in the middle of sale and after
                    sale.
                  </p>
                  <p className="text-justify">
                    Jiuding is guided by the business objective of “good faith
                    and win-win outcome” and the management principle of “people
                    foremost, taking law as outline, continuous improvement, and
                    permanent operation”. Environmental protection, excellent
                    service concept and other positive ideas have integrated
                    into our company and our products. Now we are perfectly
                    poised to work hand in hand with all our customers toward a
                    bright future through excellent products, satisfactory
                    service and high reputation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!--// About --> */}
          {/* <!-- Reasons Why we are trusted --> */}
          <div className="tm-cta-2-area tm-section tm-padding-section bg-theme">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8">
                  <div className="tm-cta tm-cta-2 text-center">
                    <h2> Reasons Why we are Trusted </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--// Reasons Why we are trusted  --> */}

          {/* <!-- Departments--> */}
          <div className="tm-about-area tm-section tm-padding-section bg-white">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div
                  // className="tm-videobox" data-black-overlay="3"
                  >
                    <img
                      src="assets/images/others/Techinical-Department.webp"
                      alt="videobox logo"
                      loading="lazy"

                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tm-about-content">
                    <h2>Techinical Department</h2>

                    <ul className="stylish-list-color">
                      <li>
                        <i className="ion-android-checkmark-circle"></i>We have
                        over 50 technical personnel.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>{" "}
                        Enviroment Simulation Techinical center.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        Refrigeration and Techinical Group.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i> Air
                        Treatment Group.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        Electrical Group.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>{" "}
                        Enviroment Simulation Techinical center.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row align-items-center tm-padding-section-top">
                <div className="col-lg-6">
                  <div className="tm-whyus-content">
                    <div className="tm-whyus-blocks">
                      <div className="tm-whyus-block">
                        <h2>Workshop-I</h2>
                        <p>
                          Workshop I: workshop for processing parts and
                          components; facilities for automatic metal plate,
                          punch press, bending and other processing devices; two
                          production lines for fin type heat exchanger;{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tm-whyus-image">
                    <div className="tm-prodetails-images">
                      <div className="tm-prodetails-largeimages">
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-I.webp"
                            data-caption="Product Zoom Image 1"
                          >
                            <img
                              src="\assets\images\about\workshop-I.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-II.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-II.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-I.webp"
                            data-caption="Product Zoom Image 1"
                          >
                            <img
                              src="\assets\images\about\workshop-I.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-II.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-II.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="tm-prodetails-thumbnails">
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-I.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-II.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>

                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-I.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-II.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center tm-padding-section-top">
                <div className="col-lg-6">
                  <div className="tm-whyus-image">
                    <div className="tm-prodetails-images">
                      <div className="tm-prodetails-largeimages">
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-2-I.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img


                              src="\assets\images\about\workshop-2-I.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-2-II.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-2-II.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-2-III.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-2-III.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>

                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-2-IV.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-2-IV.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="tm-prodetails-thumbnails">
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-2-I.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-2-II.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-2-III.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-2-IV.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tm-whyus-content">
                    <div className="tm-whyus-blocks">
                      <div className="tm-whyus-block">
                        <h2> WorkShop-II</h2>
                        <p>
                          Workshop II: Refrigerating unit assembly shop produces
                          refrigerating unit of different series.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center tm-padding-section-top">
                <div className="col-lg-6">
                  <div className="tm-whyus-content">
                    <div className="tm-whyus-blocks">
                      <div className="tm-whyus-block">
                        <h2> WorkShop-III</h2>
                        <p>
                          Workshop III: Assembly shop for air handling unit
                          produces air conditioner with constant temperature and
                          humidity, intake air conditioning for engine and other
                          products;
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tm-whyus-image">
                    <div className="tm-prodetails-images">
                      <div className="tm-prodetails-largeimages">
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-3-I.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-3-I.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-3-II.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-3-II.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-3-III.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-3-III.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-3-IV.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-3-IV.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="tm-prodetails-thumbnails">
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-3-I.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-3-II.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>{" "}
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-3-III.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-3-IV.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center tm-padding-section-top">
                <div className="col-lg-6">
                  <div className="tm-whyus-image">
                    <div className="tm-prodetails-images">
                      <div className="tm-prodetails-largeimages">
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-I.webp"
                            data-caption="Product Zoom Image 1"
                          >
                            <img
                              src="\assets\images\about\workshop-I.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-II.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-II.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-2-I.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-2-I.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-2-II.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-2-II.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-3-I.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-3-I.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-3-II.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-3-II.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-3-III.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-3-III.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="tm-prodetails-largeimage">
                          <a
                            data-fancybox="tm-prodetails-imagegallery"
                            href="\assets\images\about\workshop-3-IV.webp"
                            data-caption="Product Zoom Image 2"
                          >
                            <img
                              src="\assets\images\about\workshop-3-IV.webp"
                             alt="product logo"
                            loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="tm-prodetails-thumbnails">
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-I.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-II.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-2-I.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-2-II.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-3-I.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-3-II.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>{" "}
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-3-III.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="tm-prodetails-thumbnail">
                          <img
                            src="\assets\images\about\workshop-3-IV.webp"
                           alt="product logo"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tm-whyus-content">
                    <div className="tm-whyus-blocks">
                      <div className="tm-whyus-block">
                        <h2> Test platform</h2>
                        <p>
                          {" "}
                          Each device must undergo strict trial run experiment
                          and be delivered out of the factory after passing
                          inspection.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--// Departments  --> */}

          {/* <!-- sale and services--> */}
          <div className="tm-cta-2-area tm-section tm-padding-section bg-theme">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8">
                  <div className="tm-cta tm-cta-2 text-center">
                    <h2> Sales and Service Offices </h2>
                    <p>
                      {" "}
                      Islamabad,Karachi,Beijing, Nanjing, Jinan, Shijiazhuang,
                      Shenyang, Wuhan, Chongqing, Hefei, Zhengzhou, Nanchang,
                      Shanghai, Guangzhou, Taiyuan, Tianjin, Xiamen, Changchun,
                      Harbin, Chengdu, Guiyang, Xi’an, Hangzhou, Changsha,
                      Nanning, Dalian, Qingdao, Urumchi, Tongliao and other
                      large and medium-sized cities
                    </p>
                    <span className="mail-btn">
                      <a href="mailto:contact@jiuding.com">
                        contact@jiuding.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--// Call To Action --> */}

          {/* <!-- Contact Area --> */}
          <Contacts />
          {/* <!--// Contact Area --> */}
        </main>
        {/* <!--// Main Content --> */}
      </div>
    {/* // </motion.div> */}
    </>
  )
}

export default AboutDetail2
