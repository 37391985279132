import React from "react"
import SocialMedia from "../components/SocialMedia"
import NewsSideLinks from "../components/NewsSideLinks"
import { Link } from "react-router-dom"
import image from "./bg-breadcrumb.webp"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const TeaPartyNews = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Breadcrumb Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb.webp"
        data-white-overlay="4"
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>Jiuding New Year tea party</h2>
            <ul>
              <li>
                <Link to="/News">
                  News
                  {/* <a href="news.html">News</a> */}
                </Link>
              </li>
              <li>
                <Link to="/News-Tea-Party">
                  {/* <a href="news-jiuding-tea-party.html"> */}
                  Jiuding New Year tea party
                  {/* </a> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--// Breadcrumb Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- Blogs Area --> */}
        <div className="tm-blogs-area tm-padding-section bg-white">
          <div className="container">
            <div className="row no-gutters">
              {/* <!-- Blogs --> */}
              <div className="col-lg-8 col-12">
                <div className="tm-blog-container">
                  <div className="tm-blog blogitem">
                    <div className="tm-blog-topside tm-revealblock">
                      <img
                        src="\assets\images\news\tea-party\teaparty1.webp"
                        alt="blog img"
                      />
                    </div>
                    <div className="tm-blog-bottomside">
                      <div className="tm-blog-tags">
                        <span>
                          <Link to="/News">
                            By: Admin
                            {/* <a href="news-refrigeration.html">Admin</a> */}
                          </Link>
                        </span>
                        <span>Oct 19, 2016</span>
                      </div>
                      <h6>Jiuding New Year tea party</h6>
                      <p>
                        Yu rabbit old age, Jinlong welcome the new spring.
                        January 15, Jiuding refrigeration in the Luhe East Hall
                        held a grand welcome spring tea party and annual
                        recognition of the General Assembly.
                      </p>
                      <blockquote>
                        <p>Jiuding New Year tea party.!!!</p>
                      </blockquote>
                      <p>
                        Huangshan sunrise is also an important part of Huangshan
                        view, the third day looking forward to watch the sunrise
                        employees early bed, with the camera recorded a
                        beautiful sunrise, then set out to visit the West Grand
                        Canyon - dream area, not To have to marvel at the nature
                        of the talent, so steep, so beautiful, there is no
                        artificial modification of a mountain. After the tour of
                        the Grand Canyon, the end of the trip, all the staff
                        returned to Nanjing.
                      </p>
                    </div>

                    <SocialMedia />
                  </div>
                </div>
              </div>
              {/* <!--// Blogs --> */}

              {/* <!-- Sidebar Widgets --> */}
              <div className="col-lg-4 col-12">
                <NewsSideLinks />
              </div>
              {/* <!--// Sidebar Widgets --> */}
            </div>
          </div>
        </div>
        {/* <!--// Blogs Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}
export default TeaPartyNews