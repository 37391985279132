import { Link } from "react-router-dom";

const TestIng = () => {
  return (
    <>
      <div className="tm-header-bottomside">
        <div className="container">
          <div className="tm-header-bottominner">
            <Link to="/" className="tm-header-logo">
              <img src="assets/images/logo/logo2.webp" alt="jiuding logo" />
            </Link>
            <nav className="tm-header-nav">
              <ul>
                <li className="current">
                  <Link to="/">Home</Link>
                </li>

                <li>
                  <a href="#about">About</a>
                </li>

                <li>
                  <a href="#products">Products</a>
                </li>

                <li>
                  <a href="#services">Services</a>
                </li>

                <li>
                  <a href="#honor">Honor</a>
                </li>

                <li>
                  <a href="#news">News</a>
                </li>

                <li>
                  <a href="#projects">Projects</a>
                </li>

                <li>
                  <a href="#contact">Contact</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestIng;
