import React from "react";
import image from './bg-cta.webp';

const CallArea = () => {
  return (
    <div

      style={{ backgroundImage: `url(${image})` }}

      // id="tm-calltoaction-area"
      className="tm-cta-area tm-section tm-padding-section-index"
      data-overlay="4"
      data-bgimage={`url(${image})`}

      // data-bgimage="assets/images/bg/bg-cta.webp"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-10">
            <div className="tm-cta text-center">
              <h2>Required Any Unit Feel Free To Contact us</h2>
              <a href="#tm-contact-area" className="tm-callbutton">
                <img
                  src="assets/images/icons/icon-contact-call-white.webp"
                  alt="call icon"
                />
                <h5>24/7 Customer Care</h5>
                <h4>+92-51-2224822</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallArea;
