// NotFound component for 404 errors

import InnerHeader from "../Header/NavbarInner/InnerHeader"

const NotFound = () => {
  return (
    <>
      <InnerHeader/>
    <div style={{textAlign:"center", fontStyle:"italic"}}>
      <h2>404 Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
    </>
  )
}
export default NotFound
