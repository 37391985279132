import React from "react";
// import { LazyMotion , motion , domAnimation} from "framer-motion"
import "../components/SmoothScroll";
import Header from "../Pages/Header/OnScrollNav/Header";
import Video from "../Pages/VideoPlayer/video";
import About1 from "../Pages/About/Pages/About1";
import FunFact from "../Pages/OdoMeter/FunFact";
import ProductArea from "../Pages/Products/ProductArea";
import Services from "../Pages/ServicesDetails/Services";
import Testimonials from "../Pages/TestimonialArea/Testimonials";
import CallArea from "../Pages/CustomerCare/CallAreaPhone";
import Blog from "../Pages/NewsArea/Blog";
import CallArea2 from "../Pages/CustomerCare/CallAreaEmail";
import BrandsLogo from "../Pages/BrandLogos/Brands";
import Contacts from "../Pages/ContactArea/Contacts";

const Home = () => {
  return (
    <>
      <Header />
      <Video />
      <main className="page-content">
        <About1 />
        <FunFact />
        <ProductArea />
        <Services />
        <Testimonials />
        <CallArea />
        <Blog />
        <CallArea2 />
        <BrandsLogo />
        <Contacts />
      </main>
    </>
    // </motion.div>
    // </LazyMotion>
  );
};

export default Home;
