import { NavLink, Link } from "react-router-dom"

const InnerHeader = () => {
  return (
    <div id="Home" className="tm-header">
      <div className="tm-header-topside">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="tm-header-topside-infoleft">
                <li>
                  <b>
                    <i className="ion-android-mail"></i> Email:
                  </b>
                  <a href="mailto:info@jiudingref.com.pk">
                    info@jiudingref.com.pk
                  </a>
                </li>
                <li>
                  <b>
                    <i className="ion-android-call"></i> Contact:
                  </b>
                  <a href="tel:+92-51-2224822 "> +92-51-2224822 </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul className="tm-header-topside-inforight tm-ratingbox">
                <div className="tm-ratingbox">
                  <Link to="http://www.jiudingref.com/">
                    {/* // <a href="http://www.jiudingref.com/"> */}
                    <img src="\assets\images\icons\star2.webp" alt="chinese" />
                    {/* </a> */}
                  </Link>
                  <Link to="http://www.jiudingref.com/">
                    {/* <a href="http://www.jiudingref.com/"> */}
                    <span className="is-active">
                      <b>&nbsp;CHN</b>
                    </span>
                    {/* </a> */}
                  </Link>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="tm-header-bottomside">
        <div className="container">
          <div className="tm-header-bottominner nav">
            <Link to="/" className="tm-header-logo">
              <img src="assets/images/logo/logo2.webp" alt="jiuding logo" />
            </Link>
            <nav className="tm-header-nav">
              <ul>
                <li>
                  <NavLink
                    to="/"
                    style={({ isActive }) =>
                      isActive ? { color: "#e2000b" } : { color: "black" }
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/About"
                    style={({ isActive }) =>
                      isActive ? { color: "#e2000b" } : { color: "black" }
                    }
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Products"
                    style={({ isActive }) =>
                      isActive ? { color: "#e2000b" } : { color: "black" }
                    }
                  >
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Services"
                    style={({ isActive }) =>
                      isActive ? { color: "#e2000b" } : { color: "black" }
                    }
                  >
                    Services
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Honor"
                    style={({ isActive }) =>
                      isActive ? { color: "#e2000b" } : { color: "black" }
                    }
                  >
                    {" "}
                    Honor
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/News"
                    style={({ isActive }) =>
                      isActive ? { color: "#e2000b" } : { color: "black" }
                    }
                  >
                    News
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Projects"
                    style={({ isActive }) =>
                      isActive ? { color: "#e2000b" } : { color: "black" }
                    }
                  >
                    Projects
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Contact"
                    style={({ isActive }) =>
                      isActive ? { color: "#e2000b" } : { color: "black" }
                    }
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InnerHeader
