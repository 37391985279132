import React from "react"
import ProjectHeroslider from "../../components/ProjectHeroslider"
import SideLinkProjects from "../../components/SideLinkProjects"
import SaleOffers from "../../components/SaleOffers"
import ContactNums from "../../components/ContactTwoNums"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"

const FoodBeveragesProject = () => {
  return (
    <div>
      <InnerHeader/>
      {/* <!-- Heroslider Area --> */}
      <ProjectHeroslider />
      {/* <!--// Heeroslider Area --> */}

      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- products --> */}
        <div
          id="tm-products-area"
          className="tm-products-area tm-section tm-padding-section bg-white"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tm-sectiontitle text-center">
                  <h2>Our Projects</h2>
                  <span className="tm-sectiontitle-divider">
                    <img
                      src="assets/images/icons/icon-section-title-divider.webp"
                      alt="divider icon"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row tm-products-slider tm-slider-arrows">
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-brandlogo1.webp"
                        data-caption="Food Brand 1"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-brandlogo1.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-brandlogo2.webp"
                        data-caption="Food Brand 2"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-brandlogo2.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-brandlogo3.webp"
                        data-caption="Food Brand 3"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-brandlogo3.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-brandlogo4.webp"
                        data-caption="Food Brand 4"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-brandlogo4.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-brandlogo5.webp"
                        data-caption="Food Brand 5"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-brandlogo5.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-fisheries.webp"
                        data-caption="Food Fisheries"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-fisheries.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-huaikang.webp"
                        data-caption="Food Huaikang"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-huaikang.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-junleao.webp"
                        data-caption="Food junleao"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-junleao.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-piaxong.webp"
                        data-caption="Foton Piaxong"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-piaxong.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Single Product --> */}
              <div className="col">
                <div className="tm-prodetails-images">
                  <div className="tm-prodetails-largeimages">
                    <div className="tm-prodetails-largeimage">
                      <a
                        data-fancybox="tm-prodetails-imagegallery"
                        href="\assets\images\projects\pro-food\food-sogreen.webp"
                        data-caption="Food Green"
                      >
                        <img
                          src="\assets\images\projects\pro-food\food-sogreen.webp"
                          alt="product img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tm-section products-page-content tm-padding-section bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-12 order-1 order-lg-2">
                    <div className="tm-shop-products">
                      <div className="row mt-30-reverse">
                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-brandlogo1.webp"
                              alt="Food Brand 1"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Food Brand</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-brandlogo2.webp"
                              alt="Food Band 2"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Food Brand</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-brandlogo3.webp"
                              alt="Food Brnad 3"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Food Brand</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-brandlogo4.webp"
                              alt="Food Brand 4"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Food Brand </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-brandlogo5.webp"
                              alt="Food Brand 5"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Food Brand </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-fisheries.webp"
                              alt="Food Brand Fisheries"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Food Brand Fisheries
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-huaikang.webp"
                              alt="Food Huaikang"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Food Brand Huaikang
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-junleao.webp"
                              alt="Food Junleao"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Food Brand Junleao
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-piaxong.webp"
                              alt="Food Piaxong"
                            />
                            <div className="overlay">
                              <div className="text-overlay">Food Piaxong"</div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}

                        {/* <!-- Single Product --> */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30">
                          <div className="box-image">
                            <img
                              src="\assets\images\projects\pro-food\food-sogreen.webp"
                              alt="Food Sogreen"
                            />
                            <div className="overlay">
                              <div className="text-overlay">
                                Food Brand Sogreen
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--// Single Product --> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-12 order-2 order-lg-1">
                    <div className="widgets widgets-sidebar-shop">
                      <SideLinkProjects />
                      {/* <!--// Single Widget --> */}

                      {/* <!-- Single Widget --> */}

                      {/* <!--// Single Widget --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Call To Action --> */}
        <div
          id="tm-calltoaction-area-2"
          className="tm-cta-2-area tm-section tm-padding-section bg-theme"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8">
                <SaleOffers />
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Call To Action --> */}

        {/* <!-- Contact Area --> */}
        <ContactNums />
        {/* <!--// Contact Area --> */}
      </main>
      {/* <!--// Main Content --> */}
    </div>
  )
}

export default FoodBeveragesProject
