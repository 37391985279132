import React from "react"
import Contacts from "../../../ContactArea/Contacts"
import image from "./bg-breadcrumb-services.webp"
import InnerHeader from "../../../Header/NavbarInner/InnerHeader"
const ServicesDetail = () => {
  return (
    <div>
      <InnerHeader />
      {/* <!-- Heroslider Area --> */}
      <div
        className="tm-breadcrumb-area tm-padding-section"
        style={{ backgroundImage: `url(${image})` }}
        data-bgimage={`url(${image})`}

        // data-bgimage="assets/images/bg/bg-breadcrumb-services.webp"
      ></div>
      {/* <!--// Heroslider Area --> */}
      {/* <!-- Main Content --> */}
      <main className="page-content">
        {/* <!-- service --> */}
        <div
          id="tm-services-area"
          className="tm-services-area tm-section tm-padding-section bg-white"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tm-sectiontitle text-center">
                  <h2>Our Services</h2>
                  <span className="tm-sectiontitle-divider">
                    <img
                      src="assets/images/icons/icon-section-title-divider.webp"
                      alt="divider icon"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt-30-reverse">
              <div className="tm-whyus-content">
                <h2 style={{ textAlign: "center" }}>
                  Reasons why we're trusted because{" "}
                </h2>
                <div className="tm-whyus-blocks">
                  <div className="tm-whyus-block">
                    <h5>
                      <span>1</span>Preface
                    </h5>
                    <p className="text-justify">
                      With the increasing technological content of products,
                      users increasingly stringent requirements on the product,
                      service work has become an important factor in the
                      development of enterprises, in order to ensure the best
                      quality of service to users, in order to give full play to
                      fast and effective service advantages, my company based on
                      years of practical experience, the use of three perfect
                      interactive after-sales service system.
                    </p>
                  </div>
                  <div className="tm-whyus-block">
                    <h5>
                      <span>2</span>Service System
                    </h5>
                    <p className="text-justify">
                      The market demand for service work more and more to the
                      rapid, comprehensive and efficient development, our
                      company has long been adhere to the user-oriented service
                      approach, and gradually form a set of advanced and perfect
                      service system. Our policies and systems have been tested
                      by the market to ensure that our equipment can run better
                      in the vast domestic and international markets.
                    </p>
                    <ul className="stylish-list-color">
                      <li>
                        <i className="ion-android-checkmark-circle"></i>External
                        Customer Service.
                      </li>
                      <p className="text-justify">
                        The external user service department is composed of
                        technical service personnel, professional installation
                        and maintenance personnel, quality record analysis
                        personnel, customer contact and return service staff.
                        With the office abroad, responsible for the office area
                        of the after-sales service work.
                      </p>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        Corporate Customer Service.
                      </li>
                      <p className="text-justify">
                        <b>Personnel: </b>Company customer service by the
                        technical service personnel, professional installation
                        and maintenance personnel, quality record analysis
                        staff, technical support engineers. Most of the
                        installation and maintenance personnel with more than
                        four years of air conditioning maintenance experience,
                        can quickly and accurately determine the cause of each
                        unit failure and can quickly troubleshoot. Quality
                        record analysis staff have more than four years of work
                        experience.
                        <br />
                        <b>The scope of work: </b>Unified management of the
                        company after-sales service work to support the
                        maintenance service throughout the country, to develop
                        service plans, training programs, service feedback
                        statistical analysis.
                      </p>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>Regional
                        User Services.
                      </li>
                      <p className="text-justify">
                        By the backbone of the service team, responsible for the
                        region's after-sales service work and management,
                        resident in the district's major cities, daily tour
                        work, according to need, at any time to support the
                        region or adjacent areas of service work.
                      </p>
                    </ul>
                  </div>
                  <div className="tm-whyus-block">
                    <h5>
                      <span>3</span>Work Program
                    </h5>
                    <p className="text-justify">
                      <b>Create user profile: </b> (The name, address,
                      telephone, zip code, contact person, etc.), to the user
                      service department to enter the computer (such as the
                      purchase of units, the use of units of detailed
                      information (name, address, telephone, zip code, contacts,
                      etc.), to the user service department to enter the
                      computer Management, the establishment of detailed user
                      files. So that the service can be tracked at any time.
                      <br />
                      <b>User warranty card: </b>Assist users to clarify the
                      contents of the warranty, the warranty period, the rights
                      and obligations of both the user and the company. Warranty
                      period, the user can repair the warranty card free of
                      charge, replace the fault spare parts.
                    </p>
                  </div>
                  <div className="tm-whyus-block">
                    <h5>
                      <span>4</span>Warranty period of service
                    </h5>
                    <p className="text-justify">
                      <b>Warranty cycle: </b>Since the commissioning of the date
                      of acceptance, the company provides 12 months of the
                      "Three Guarantees" service, during the normal use of
                      equipment in the case of all the problems arising from my
                      company is responsible for the settlement, the cost
                      required by our company. Provide technical service and
                      maintenance personnel at any time to ensure the normal
                      operation of the equipment (if the equipment by the
                      company personnel debugging, or because the use of human
                      and force major caused by equipment, such as downtime or
                      damage).
                      <br />
                      <b>Equipment commissioning: </b>The company according to
                      the date of the contract requirements or received a user
                      equipment debugging notice, send debugging engineers at
                      the designated time to reach the user site.
                    </p>
                    <ul className="stylish-list-color">
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        Instructions include the installation.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>If the
                        customer requests to provide guidance to install, work:
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        Pre-provision of equipment installation base map, system
                        water and electricity pipeline connection instructions.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>With the
                        user and representatives of the parties on the equipment
                        model, quantity, random documents, spare parts, tools
                        and equipment appearance, acceptance, and record.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>Site to
                        guide the user or the installation unit for equipment in
                        place, hydropower pipeline connection.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        Pre-provision of equipment installation base map, system
                        water and electricity pipeline connection instructions.
                      </li>
                    </ul>
                    <p className="text-justify">
                      <b>Equipment commissioning and acceptance: </b>Party A and
                      the installation unit shall provide the necessary water
                      source, power welding equipment and other auxiliary
                      equipment and materials for the installation and
                      commissioning of the equipment. In conjunction with the
                      Company's successful completion of the commissioning work
                    </p>
                    <p className="text-justify">
                      We are responsible for debugging equipment, so that the
                      operation of the parameters, indicators have reached the
                      product design standards to meet the user requirements for
                      the use of equipment. According to the standard
                      requirements of the equipment acceptance, with the
                      acceptance of the party to test and fill in the record for
                      the record.
                    </p>
                    <ul className="stylish-list-color">
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        Installation and commissioning time can be adjusted
                        according to the user's request.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>Site
                        training, the entire process to guide the operation and
                        maintenance personnel to ensure that the following:
                      </li>
                      <p className="text-justify">
                        <b>During installation and commissioning: </b>{" "}
                      </p>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>Basic
                        structure of equipment,
                        <i className="ion-android-checkmark-circle"></i> Basic
                        working principle of equipment.
                      </li>
                      <p className="text-justify">
                        <b>After the device is commissioned: </b>{" "}
                      </p>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        Equipment operation methods,{" "}
                        <i className="ion-android-checkmark-circle"></i>
                        Troubleshooting methods,{" "}
                        <i className="ion-android-checkmark-circle"></i>Routine
                        maintenance methods and precautions.
                      </li>
                    </ul>
                  </div>
                  <div className="tm-whyus-block">
                    <h5>
                      <span>5</span>Work Program
                    </h5>
                    <p className="text-justify">
                      Warranty period, if the user needs, the company can be
                      paid for a lifetime to provide comprehensive, preferential
                      services, regular inspection and maintenance of
                      maintenance, at any time on the user operation and
                      maintenance personnel to provide advice and guidance.
                      Maintenance once a year, the main contents:
                    </p>
                    <ul className="stylish-list-color">
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        compressor maintenance, replacement of wearing parts.
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>
                        evaporator, condenser cleaning new;
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>{" "}
                        expansion valve, electrical control components set the
                        effective value, testing and maintenance.
                      </li>
                    </ul>
                    <p className="text-justify">
                      To ensure that wearing parts, spare parts spare parts
                      supply. On the non-supply of refrigeration and air
                      conditioning auxiliary equipment to provide reasonable and
                      effective maintenance update recommendations to ensure the
                      normal operation of the system.
                    </p>
                  </div>
                  <div className="tm-whyus-block">
                    <h5>
                      <span>6</span>Emergency service response time
                    </h5>
                    <ul className="stylish-list-color">
                      <li>
                        <i className="ion-android-checkmark-circle"></i>Normal
                        service response time is 48 hours (2 working days).
                      </li>
                      <li>
                        <i className="ion-android-checkmark-circle"></i>Service
                        response time refers to the company received a service
                        notice to reach the service location of the longest
                        time.
                      </li>

                      <li>
                        <i className="ion-android-checkmark-circle"></i>24 hours service hotline: <a
                        href="tel:"> +86-15251731109</a> / <a href="tel:">+92-51-2224822</a>, at
                        any time on the user's emergency requirements for emergency technical advice
                        and support.</li>


                      {/* <li>
                        <i className="ion-android-checkmark-circle"></i>24 hours
                        service hotline: 400-889-7528 / 13851950751 /
                        025-57138853, at any time on the user's emergency
                        requirements for emergency technical advice and support.
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Call To Action --> */}
        <div
          id="tm-calltoaction-area-2"
          className="tm-cta-2-area tm-section tm-padding-section bg-theme"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8">
                <div className="tm-cta tm-cta-2 text-center">
                  <h2> Sales and Services Offices </h2>
                  <p>
                    Beijing, Nanjing, Jinan, Shijiazhuang, Shenyang, Wuhan,
                    Chongqing, Hefei, Zhengzhou, Nanchang, Shanghai, Guangzhou,
                    Taiyuan, Tianjin, Xiamen, Changchun, Harbin, Chengdu,
                    Guiyang, Xi’an, Hangzhou, Changsha, Nanning, Dalian,
                    Qingdao, Urumchi, Tongliao and other large and medium-sized
                    cities
                  </p>
                  <span className="mail-btn">
                    <a href="afridi@Jiudingref.com.pk">
                      afridi@Jiudingref.com.pk
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Contact Area --> */}
        <Contacts />
        {/* <!--// Contact Area --> */}
      </main>
    </div>
  )
}

export default ServicesDetail
