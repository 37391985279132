import AddressBtn from "./AddressBtn"
import image from "./footer-bg.webp"
import { Link } from "react-router-dom"
// import Tabs from './tabs';

const Footer = () => {
  return (
    <div
      style={{ backgroundImage: `url(${image})` }}
      className="tm-footer-topside tm-padding-section-index bg-darklight"
      data-bgimage={`url(${image})`}

      // data-bgimage="assets/images/bg/footer-bg.webp"
    >
      <div className="container">
        <div className="widgets widgets-footer row">
          <div className="col-lg-3 col-md-6 col-12">
            <div className="single-widget widget-info">
              <Link to="/" className="widget-info-logo">
                {/* <a className="widget-info-logo" href="index-refrigeration.html"> */}
                <img
                  src="assets/images/logo/logo-white-2.webp"
                  alt="white logo"
                />
                {/* </a> */}
              </Link> 
              <AddressBtn />
              {/* <Tabs /> */}
              <ul>
                <li>
                  <a href="#Social-facebook.html">
                    <i className="ion-social-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#social-twitter.html">
                    <i className="ion-social-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#social-insta.html">
                    <i className="ion-social-instagram-outline"></i>
                  </a>
                </li>
                <li>
                  <a href="#social-pinterest.html">
                    <i className="ion-social-pinterest-outline"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12">
            <div className="single-widget widget-quicklinks">
              <h4 className="widget-title">Services</h4>
              <ul>
                <li>
                  <Link to="/product-ice-rink">
                    {/* <a href="product-ice-rink.html"> */}
                    Ice Rinkers Series
                    {/* </a> */}
                  </Link>
                </li>
                <li>
                  <Link to="/product-air-treatment">
                    {/* <a href="product-air-treatment.html"> */}
                    Air Treatment Series
                    {/* </a> */}
                  </Link>
                </li>
                <li>
                  <Link to="/product-refrigerating-unit">
                    {/* <a href="product-refrigerating-unit.html"> */}
                    Refigeration Units
                    {/* </a> */}
                  </Link>
                </li>
                <li>
                  <Link to="/product-food-refrigeration">
                    {/* <a href="product-food-refrigeration.html"> */}
                    Food Refrigeration
                    {/* </a> */}
                  </Link>
                </li>
                <li>
                  <Link to="/product-enviromental-systems">
                    {/* <a href="product-enviromental-systems.html"> */}
                    Enviromental Services
                    {/* </a> */}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12">
            <div className="single-widget widget-recentpost-2">
              <h4 className="widget-title">Recent Posts</h4>
              <ul>
                <li>
                  <h6>
                    <Link to="/News">
                      {/* <a href="news-refrigeration.html"> */}
                      Autumn in October Huangshan
                      {/* </a> */}
                    </Link>
                  </h6>
                  <span>
                    <i className="ion-android-calendar"></i>Jan 15, 2019
                  </span>
                </li>
                <li>
                  <h6>
                    <Link to="/News">
                      {/* <a href="news-refrigeration.html"> */}
                      Jiuding New Year tea party
                      {/* </a> */}
                    </Link>
                  </h6>
                  <span>
                    <i className="ion-android-calendar"></i> Oct 19, 2016
                  </span>
                </li>
                <li>
                  <h6>
                    <Link to="/News">
                      {/* <a href="news-refrigeration.html"> */}
                      Mid-Autumn Festival, National Day
                      {/* </a> */}
                    </Link>
                  </h6>
                  <span>
                    <i className="ion-android-calendar"></i> Sep 08, 2011
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12">
            <div className="single-widget widget-hours">
              <h4 className="widget-title">Opening Hours</h4>
              <p>Feel free to contact us Or visit our site.</p>
              <ul>
                <li>
                  <span>Mon - Tue</span>: 9.00 AM - 18.00 PM
                </li>
                <li>
                  <span>Wed - Fri</span>: 8.00 AM - 17.00 PM
                </li>
                <li>
                  <span>Saturday</span>: 9.00 PM - 15.00 PM
                </li>
                <li>
                  <span>Sunday</span>: Closed
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
